<!--课件下载组件-->
<template>
  <div class="courseFile">
    <!-- <div class="title">课件目录：</div> -->
     <!-- <div class="title flex_just_between flex_align_center">
         <span>课件目录：</span>
     </div> -->
    <div class="flex flex_wrap bg_gary">
      <div class="coursewareItem borderBox  flex_just_between flex_align_center"
           v-for="(item,index) in courseFileList" :key="index" @click="previewFile(item)">
        <template v-if="item.isImage">
          <img class="iconFile" :src="item.nodePath"/>
        </template>
        <template v-else>
          <!-- {{item.typeName}} -->

          <img class="iconFile" v-if="item.typeName=='.doc'||item.typeName=='.docx'"
               src="../../assets/images/experiment/file_word.png"/>
          <img class="iconFile" v-else-if="item.typeName=='.xls'||item.typeName=='.xlsx'"
               src="../../assets/images/experiment/file_xlsx.png"/>
          <img class="iconFile" v-else-if="item.typeName=='.ppt'||item.typeName=='.pptx'"
               src="../../assets/images/experiment/file_ppt.png"/>
          <img class="iconFile" v-else-if="item.typeName=='.pdf'" src="../../assets/images/experiment/file_pdf.png"
               mode="widthFix"/>
          <img class="iconFile" v-else-if="item.typeName=='.psd'" src="../../assets/images/experiment/psd.png"
               mode="widthFix"/>
          <img class="iconFile imgobj" v-else-if="item.typeName=='.mp4'" src="../../assets/images/experiment/mp4.png"
               mode="widthFix"/>
          <img class="iconFile" v-else src="../../assets/images/experiment/file_none.png" mode="widthFix"/>
          <!-- <img class="iconFile" v-else-if="item.typeName=='.'"  src="../../assets/images/experiment/file_none.png" mode="widthFix"/> -->

        </template>

        <div class="flex_page flex_main">
          <div class="coursewareTit txtOverFlow1">{{ item.coursewareName }}</div>
          <div class="coursewareTime">上传时间：{{ item.createTime }}</div>
          <!-- <view class="coursewareDownLoad" data-idx="{{index}}" data-nodeid="{{item.nodeId}}" catchtap="downLoadCourseware">下载</view> -->
        </div>
        <img class="iconLoad" src="../../assets/images/experiment/ico_download.png" alt="" @click.stop="downLoadFile(item)">
      </div>
    </div>
  </div>

</template>

<script>
import AsingleFile from "@/utils/AsingleFile";
import $ from 'jquery'
export default {
  name: 'courseFile',
  data () {
    return {
      token: localStorage.getItem("pc_token")??'',
    }
  },
  props:{
    courseFileList:Array,
  },
  mounted(){},
  methods:{
    //预览课件
    previewFile(item) {
      if(this.token == ''){
        $("#loginBtn").click()
        localStorage.removeItem("pc_token");    
        localStorage.removeItem("pc_data"); 
        return false;
      }
       AsingleFile(item.nodePath)
    },
    //下载课件item
    downLoadFile(item) {
      this.$emit('download',item)
    },
  },
  watch:{
    courseFileList(val){
      console.log('copyUserName', val)
    },
  }
}
</script>
<style scoped>
.title {
  font-size: 0.1rem;
  line-height: 0.4rem;
  text-align: left;
  font-weight: bold;
}
.flex_main, .flex_page_main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.flex_wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex_align_center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.coursewareItem {
  width: 49%;
  margin-bottom: 0.08rem;
  padding: 0.1rem 0.1rem 0.1rem;
  box-sizing: border-box;
  background: #fff;
  /* border-bottom-right-radius: 18rpx; */
  position: relative;
  flex-wrap: wrap;
  font-size: 14px;
  text-align: left;
  margin-right: 2%;
}


.coursewareItem:nth-child(2n) {
  margin-right: 0;
}
.borderBox {
  border: 1px solid #ededed;
  box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
}
.iconFile {
  width: 0.3rem;
  height: 0.4rem;
  margin-right: 0.15rem;
  object-fit: cover;
}
.flex_just_between, .flex_page {
    display: flex;
    justify-content: space-between;
}

.flex_page {
    flex-direction: column;
}

.flex_main, .flex_page_main {
    flex: 1;
}
.iconLoad {
  margin-left: 0.1rem;
  width: 0.25rem;
  height: 0.25rem;
  cursor: pointer;
}

.coursewareTime {
  color: #666;
  font-size: 0.05rem;
}
.txtOverFlow1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.txtOverFlow2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bg_gary {
  padding: 0.1rem 0.15rem;
  background: #f3f3f3;
  /* margin: 0.2rem 0; */
  box-sizing: border-box;
}
.imgobj {
   width: 0.3rem;
  height: 0.3rem;
  margin-right: 0.15rem;
  object-fit: cover;
}
</style>

