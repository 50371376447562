<!-- 学生实验详情 -->
<template>
  <div class="experimentInfoStuPage">
    <div class="experimentInfoStuContainer">
      <div>
        <div class="experimentModel">
          <div class="modelBox1 borderBox">
            <!-- <div class="modeTitle">{{ experimentInfo.experimentName }}</div> -->
            <el-row
              :gutter="10"
              v-if="
                experimentInfo.curriculumVideoList &&
                experimentInfo.curriculumVideoList.length > 0
              "
            >
              <el-col :span="24">
                <div class="grid-content">
                  <div>
                    <video
                      controls
                      id="video"
                      :ref="curriculumVideoLists.videoId"
                      @timeupdate="videoupdate($event, curriculumVideoLists)"
                      @play="videoplay($event, curriculumVideoLists)"
                      :style="{
                        width: '100%',
                        height: '3rem',
                      }"
                    >
                      <source :src="videoUrl" type="video/mp4" />
                    </video>
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <span
                        style="
                          padding-left: 10px;
                          font-size: 15px;
                          font-weight: 600;
                        "
                        >{{ experimentInfo.curriculumTheme }}/{{
                          experimentInfo.experimentName
                        }}</span
                      >
                      <span class="TimeTexrts">{{
                        recordTimeTexrt(curriculumVideoLists)
                      }}</span>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div v-else class="nodataTxt">- 暂无资源信息 -</div>
          </div>
        </div>
      </div>

      <div class="experimentTabBox">
        <div class="experimentModel">
          <div
            class="modelBox borderBox"
            v-if="
              experimentInfo.curriculumVideoList &&
              experimentInfo.curriculumVideoList.length > 1
            "
          >
            <!-- <div class="modeTitle">{{ experimentInfo.experimentName }}</div> -->
            <!-- <el-row :gutter="10">
              <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="8">
                
              </el-col>
            </el-row> -->
               <div
                  class="grid-content"
                  style="display: flex; width: 100%; flex-wrap: wrap"
                >
                  <div
                    v-for="(item, index) in experimentInfo.curriculumVideoList"
                    :key="index"
                    style="width:24%; margin-right: 1%"
                  >
                    <div class="box" @click="aaa(item.videoUrl)" style="cursor: pointer;"></div>
                    <!-- 
                        height: isMobile ? '1.5rem' : '1.5rem', -->
                    <video
                      controls
                      id="video"
                      :ref="item.videoId"
                      @timeupdate="videoupdate($event, item)"
                      @play="videoplay($event, item)"
                      :style="{
                        width: '100%',
                        height: '1rem',
                      }"
                    >
                      <source :src="item.videoUrl" type="video/mp4" />
                    </video>
                    <div style="cursor: pointer;text-align: center;font-size: 14px;margin-bottom: 5px;"  @click="aaa(item.videoUrl)">{{item.videoName}}</div>
                  </div>
                </div>
          </div>
        </div>
        <div class="experimentInfoContent borderBox flex_main">
          <div class="experimentInfoTabs flex">
            <div
              v-for="(item, index) in tabsList"
              :key="index"
              :class="[currentTab == index ? 'colorY' : '']"
              @click="changeTab(index)"
            >
              {{ item }}
            </div>
          </div>
          <div class="experimentInfoBox">
            <!-- 课件下载 -->
            <template v-if="currentTab == 0">
              <!--  课件下载 -->
              <courseFile
                v-if="courseFileList.length > 0"
                :courseFileList="courseFileList"
                @download="downLoadFile"
                @preview="previewFile"
              ></courseFile>
              <div v-else class="nodataTxt">- 暂无课件信息 -</div>
            </template>
            <!-- 课前预习 -->
            <template v-if="currentTab == 1">
              <div class="collapse" v-if="isHas == 1">
                <el-collapse v-model="activeNames">
                  <el-collapse-item title="课前练习课件资源" name="1">
                    <div
                      class="flex flex_wrap bg_gary"
                      v-if="0 != preGuidance.coursewareList.length"
                    >
                      <div
                        class="
                          coursewareItem
                          borderBox
                          flex_just_between flex_align_center
                        "
                        v-for="(item, index) in preGuidance.coursewareList"
                        :key="index"
                        @click="previewFiles(item)"
                      >
                        <template v-if="getFileType(item.nodePath) == 'image'">
                          <img class="iconPngFile" :src="item.nodePath" />
                        </template>
                        <template v-else>
                          <img
                            class="iconFile"
                            v-if="
                              getFileType(item.nodePath) == 'word' ||
                              getFileType(item.nodePath) == '.docx'
                            "
                            src="../../assets/images/experiment/file_word.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'excel' ||
                              getFileType(item.nodePath) == '.xlsx'
                            "
                            src="../../assets/images/experiment/file_xlsx.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'ppt' ||
                              getFileType(item.nodePath) == '.pptx'
                            "
                            src="../../assets/images/experiment/file_ppt.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'pdf'"
                            src="../../assets/images/experiment/file_pdf.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'psd'"
                            src="../../assets/images/experiment/psd.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile imgobj"
                            v-else-if="getFileType(item.nodePath) == 'video'"
                            src="../../assets/images/experiment/mp4.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else
                            src="../../assets/images/experiment/file_none.png"
                            mode="widthFix"
                          />
                        </template>
                        <div class="flex_page flex_main">
                          <div class="coursewareTit txtOverFlow1">
                            {{ item.nodeName }}
                          </div>
                          <div class="coursewareTime">
                            上传时间：{{ item.createTime }}
                          </div>
                        </div>
                        <img
                          class="iconLoad"
                          src="../../assets/images/experiment/ico_download.png"
                          alt=""
                          @click.stop="downLoadFile(item)"
                        />
                      </div>
                    </div>
                    <div
                      class="bg_gary"
                      style="text-align: center"
                      v-if="0 == preGuidance.coursewareList.length"
                    >
                      无课件资源
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="课前练习作业资源" name="2">
                    <div
                      class="homeworkImgList flex flex_wrap bg_gary"
                      v-if="0 != preGuidance.homeworkList.length"
                    >
                      <div
                        class="
                          coursewareItem
                          borderBox
                          flex_just_between flex_align_center
                        "
                        v-for="(item, index) in preGuidance.homeworkList"
                        :key="index"
                        @click="previewFiles(item)"
                      >
                        <template v-if="getFileType(item.nodePath) == 'image'">
                          <img class="iconPngFile" :src="item.nodePath" />
                        </template>
                        <template v-else>
                          <img
                            class="iconFile"
                            v-if="
                              getFileType(item.nodePath) == 'word' ||
                              getFileType(item.nodePath) == '.docx'
                            "
                            src="../../assets/images/experiment/file_word.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'excel' ||
                              getFileType(item.nodePath) == '.xlsx'
                            "
                            src="../../assets/images/experiment/file_xlsx.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'ppt' ||
                              getFileType(item.nodePath) == '.pptx'
                            "
                            src="../../assets/images/experiment/file_ppt.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'pdf'"
                            src="../../assets/images/experiment/file_pdf.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'psd'"
                            src="../../assets/images/experiment/psd.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile imgobj"
                            v-else-if="getFileType(item.nodePath) == 'video'"
                            src="../../assets/images/experiment/mp4.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else
                            src="../../assets/images/experiment/file_none.png"
                            mode="widthFix"
                          />
                        </template>
                        <div class="flex_page flex_main">
                          <div class="coursewareTit txtOverFlow1">
                            {{ item.nodeName }}
                          </div>
                          <div class="coursewareTime">
                            上传时间：{{ item.createTime }}
                          </div>
                        </div>
                        <img
                          class="iconLoad"
                          src="../../assets/images/experiment/ico_download.png"
                          alt=""
                          @click.stop="downLoadFile(item)"
                        />
                      </div>
                    </div>
                    <div
                      class="bg_gary"
                      style="text-align: center"
                      v-if="0 == preGuidance.homeworkList.length"
                    >
                      无作业资源
                    </div>
                  </el-collapse-item>
                  <el-collapse-item title="课前练习视频资源" name="3">
                    <div
                      class="homeworkImgList flex flex_wrap bg_gary"
                      v-if="0 != preGuidance.videoList.length"
                    >
                      <div
                        class="
                          coursewareItem
                          borderBox
                          flex_just_between flex_align_center
                        "
                        v-for="(item, index) in preGuidance.videoList"
                        :key="index"
                        @click="previewFiles(item)"
                      >
                        <template v-if="getFileType(item.nodePath) == 'image'">
                          <img class="iconPngFile" :src="item.nodePath" />
                        </template>
                        <template v-else>
                          <img
                            class="iconFile"
                            v-if="
                              getFileType(item.nodePath) == 'word' ||
                              getFileType(item.nodePath) == '.docx'
                            "
                            src="../../assets/images/experiment/file_word.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'excel' ||
                              getFileType(item.nodePath) == '.xlsx'
                            "
                            src="../../assets/images/experiment/file_xlsx.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="
                              getFileType(item.nodePath) == 'ppt' ||
                              getFileType(item.nodePath) == '.pptx'
                            "
                            src="../../assets/images/experiment/file_ppt.png"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'pdf'"
                            src="../../assets/images/experiment/file_pdf.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else-if="getFileType(item.nodePath) == 'psd'"
                            src="../../assets/images/experiment/psd.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile imgobj"
                            v-else-if="getFileType(item.nodePath) == 'video'"
                            src="../../assets/images/experiment/mp4.png"
                            mode="widthFix"
                          />
                          <img
                            class="iconFile"
                            v-else
                            src="../../assets/images/experiment/file_none.png"
                            mode="widthFix"
                          />
                        </template>
                        <div class="flex_page flex_main">
                          <div class="coursewareTit txtOverFlow1">
                            {{ item.nodeName }}
                          </div>
                          <div class="coursewareTime">
                            上传时间：{{ item.createTime }}
                          </div>
                        </div>
                        <img
                          class="iconLoad"
                          src="../../assets/images/experiment/ico_download.png"
                          alt=""
                          @click.stop="downLoadFile(item)"
                        />
                      </div>
                    </div>
                    <div
                      class="bg_gary"
                      style="text-align: center"
                      v-if="0 == preGuidance.videoList.length"
                    >
                      无视频资源
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
              <div v-else class="nodataTxt">- 暂无课前预习信息 -</div>
            </template>
            <!-- 在线练习 -->
            <template v-if="currentTab == 2">
              <div v-if="practiceList.length > 0">
                <div
                  v-if="practiceList.length > 0 && isShowPracticeList"
                  class="flex flex_wrap bg_gary"
                >
                  <div
                    class="
                      coursewareItem
                      borderBox
                      flex_just_between flex_align_center
                    "
                    v-for="(item, index) in practiceList"
                    :key="index"
                    style="width: 100%"
                  >
                    <div style="font-size: 30px; padding-right: 10px">
                      <i class="el-icon-edit-outline"></i>
                    </div>
                    <div class="flex_page flex_main">
                      <div class="coursewareTit txtOverFlow1">
                        {{ item.papersName }}
                      </div>
                      <div class="coursewareTime">
                        练习开始时间：{{ item.examinationStartTime }}
                      </div>
                      <div class="coursewareTime">
                        练习结束时间：{{ item.examinationEndTime }}
                      </div>
                    </div>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="startPractice(item.papersId)"
                      v-if="
                        item.answerNumber > item.practiceNum &&
                        item.isConduct == 1 &&
                        !item.userPapersId
                      "
                    >
                      开始练习
                    </el-button>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="
                        getUserPracticeList(
                          item.userPapersId,
                          true,
                          item.papersId
                        )
                      "
                      v-if="
                        (item.answerNumber > item.practiceNum ||
                          (item.answerNumber == item.practiceNum &&
                            !isSubmitPaper)) &&
                        item.userPapersId != '' &&
                        item.isConduct == 1
                      "
                    >
                      继续练习
                    </el-button>
                    <el-button
                      type="success"
                      icon="el-icon-view"
                      @click="
                        getUserPracticeList(
                          item.userPapersId,
                          false,
                          item.papersId
                        )
                      "
                      v-if="item.userPapersId != ''"
                      >查看练习
                    </el-button>
                  </div>
                </div>
                <div
                  v-if="practiceList.length == 0 && isShowPracticeList"
                  class="nodataTxt"
                >
                  - 暂无练习信息 -
                </div>
              </div>
              <div v-else class="nodataTxt">- 暂无练习信息 -</div>
            </template>
            <!-- 实验作业 -->
            <template v-if="currentTab == 3">
              <div
                class=""
                v-if="
                  curriculumHomeworkList && curriculumHomeworkList.length > 0
                "
              >
                <!-- <div class="title flex_just_between flex_align_center">
                  <span>实验作业：</span>
                </div> -->
                <div class="homeworkImgList flex flex_wrap bg_gary">
                  <div
                    class="
                      coursewareItem
                      borderBox
                      flex_just_between flex_align_center
                    "
                    v-for="(item, index) in curriculumHomeworkList"
                    :key="index"
                    @click.prevent="previewFile(item)"
                  >
                    <template v-if="item.isImage">
                      <img class="iconPngFile" :src="item.nodePath" />
                    </template>
                    <template v-else>
                      <img
                        class="iconFile"
                        v-if="
                          item.typeName == '.doc' || item.typeName == '.docx'
                        "
                        src="../../assets/images/experiment/file_word.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          item.typeName == '.xls' || item.typeName == '.xlsx'
                        "
                        src="../../assets/images/experiment/file_xlsx.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          item.typeName == '.ppt' || item.typeName == '.pptx'
                        "
                        src="../../assets/images/experiment/file_ppt.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="item.typeName == '.pdf'"
                        src="../../assets/images/experiment/file_pdf.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile"
                        v-else-if="item.typeName == '.psd'"
                        src="../../assets/images/experiment/psd.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile imgobj"
                        v-else-if="item.typeName == '.mp4'"
                        src="../../assets/images/experiment/mp4.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile"
                        v-else
                        src="../../assets/images/experiment/file_none.png"
                        mode="widthFix"
                      />
                    </template>
                    <div class="flex_page flex_main">
                      <div class="coursewareTit txtOverFlow1">
                        {{ item.homeworkName }}
                      </div>
                      <div class="coursewareTime">
                        上传时间：{{ item.createTime }}
                      </div>
                    </div>
                    <img
                      class="iconLoad"
                      src="../../assets/images/experiment/ico_download.png"
                      alt=""
                      @click.stop="downLoadFile(item)"
                    />
                  </div>
                </div>
                <div
                  class="title flex_just_between flex_align_center"
                  v-if="token != ''"
                >
                  <span>上传作业：</span>
                </div>
                <template v-if="homeworkList.length > 0 && token != ''">
                  <div
                    class="homeworkImgList bg_gary"
                    v-for="(item, index) in homeworkList"
                    :key="index"
                  >
                    <template v-if="item.homeworkFileList.length > 0">
                      <div >
                        <ul class="el-upload-list el-upload-list--text is-disabled">
                          <li tabindex="0" itemid="" class="el-upload-list__item is-success"
                            v-for="(temp, tempIndex) in item.homeworkFileList" :key="tempIndex"
                            @click="handleHomeworkFilePreviewNew(temp.url)"
                          >
                          <a class="el-upload-list__item-name"><i class="el-icon-document"></i>{{temp.name}}</a>
                          <label class="el-upload-list__item-status-label">
                            <i class="el-icon-upload-success el-icon-circle-check"></i>
                          </label>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else>
                      <div class="homeworkImgList bg_gary">
                        <el-upload
                          ref="uploadHomeworkExcel"
                          action=""
                          :limit="6"
                          :data="uploadHomeworkData"
                          :on-preview="handleHomeworkFilePreview"
                          :http-request="beforeUploadHomeworkFile"
                          :auto-upload="true"
                          multiple
                          :disabled="false"
                          :on-remove="handleRemoveHomeworkFile"
                          :file-list="homeworkFileList"
                        >
                          <el-button size="small" plain>选择文件</el-button>
                        </el-upload>
                      </div>
                      <div style="text-align: center">
                        <el-button type="primary" @click="updateUserHomework"
                          >提交作业</el-button
                        >
                      </div>
                    </template>
                  </div>
                  <div
                    class="homeworkImgList bg_gary" style="margin-top: 0.08rem;"
                    v-for="(item, index) in homeworkList"
                    :key="index"
                  >
                    <template v-if="item.homeworkFileList.length > 0">
                      <p
                        class="coursewareTime"
                        style="font-size: 0.08rem;padding: 0.05rem 0rem;0.1rem 0rem 0.05rem 0rem;"
                      >
                        上传时间：{{ item.updateTime }}
                      </p>
                      <p
                        v-if="item.isRead == 1 && item.userHomeworkReadScore"
                        class="getScoreTxt"
                        style="
                          font-size: 0.08rem;
                          padding: 0.05rem 0rem;
                        "
                      >
                        得分：<span style="font-size: 0.1rem;color: red;">{{ item.userHomeworkReadScore }}</span>
                      </p>
                      <p
                        v-if="item.isRead == 1 && item.userHomeworkReadComments"
                        class="coursewareTime"
                        style="font-size: 0.08rem; padding: 0.05rem 0rem"
                      >
                        评语：<span style="font-size: 0.05rem;color: red;">{{ item.userHomeworkReadComments }}</span>
                      </p>
                    </template>
                    
                  </div>
                </template>
                <template v-if="homeworkList.length <= 0 && token != ''">
                  <div class="homeworkImgList bg_gary">
                    <el-upload
                      ref="uploadHomeworkExcel"
                      action=""
                      :limit="6"
                      :data="uploadHomeworkData"
                      :on-preview="handleHomeworkFilePreview"
                      :http-request="beforeUploadHomeworkFile"
                      :auto-upload="true"
                      multiple
                      :disabled="false"
                      :on-remove="handleRemoveHomeworkFile"
                      :file-list="homeworkFileList"
                    >
                      <el-button size="small" plain>选择文件</el-button>
                      <!-- <div slot="tip" class="el-upload__tip">只能上传csv/xslx/xsl/txt文件</div> -->
                    </el-upload>
                  </div>
                  <div style="text-align: center">
                    <el-button type="primary" @click="updateUserHomework"
                      >提交作业</el-button
                    >
                  </div>
                </template>
              </div>
              <div v-else class="nodataTxt">- 暂无作业信息 -</div>
            </template>
            <!-- 课程提问 -->
            <template v-if="currentTab == 4">
              <div class="courseQuestionContainer">
                <div
                  class="courseQuestionInps flex_align_center"
                  v-if="token != ''"
                >
                  <!-- <i
                    class="el-input__icon el-icon-arrow-left returnIcon"
                    @click="hideQuesContent"
                    style="font-size: 0.18rem"
                  ></i> -->
                  <div class="flex_main ml20 mr20">
                    <!-- <div class="flex searchInp" >
                      <el-input
                        placeholder="输入会员名搜索"
                        prefix-icon="el-icon-search"
                        v-model="memberName_search"
                        @keyup.enter.native="keydown()"
                        clearable
                        @clear="clearSearch"
                      >
                      </el-input>
                    </div> -->
                  </div>
                  <el-button type="primary" @click="showTiQuesPop('')"
                    >提交新问答</el-button
                  >
                </div>
                <div
                  class="courseQuestionsBox2"
                  v-if="courseQuesList && courseQuesList.length > 0"
                >
                  <el-row
                    :gutter="12"
                    v-for="(item, index) in courseQuesList"
                    :key="index"
                    style="margin-bottom: 0.2rem"
                  >
                    <el-col :span="24" style="min-height: 1.2rem">
                      <el-card shadow="hover" class="courseQuestionsBox_item">
                        <div class="flex_align_center flex_just_between">
                          <div class="flex_align_center" style="height: 0.6rem">
                            <img
                              v-if="
                                item.headPortrait != null &&
                                item.headPortrait != ''
                              "
                              :src="item.headPortrait"
                              class="mineIcon"
                            />
                            <img
                              v-else
                              src="../../assets/images/experiment/noHeadIcon.jpg"
                              class="mineIcon"
                            />
                            <div
                              class="userName txtLeft"
                              v-if="item.memberUserName != null"
                            >
                              {{ item.memberUserName }}
                            </div>
                          </div>
                        </div>
                        <div class="txtContent txtLeft">
                          {{ item.questionsDes }}
                        </div>
                        <div
                          class="picsItemBox"
                          v-if="item.pics != null && item.pics.length > 0"
                        >
                          <img
                            v-for="(temp, idx) in item.pics"
                            class="picsItem"
                            :src="temp.questionPic"
                            :key="idx"
                            width="217"
                            height="180"
                            @click="previewFile2(temp.questionPic)"
                          />
                        </div>
                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                            <div class="grid-content userInfoTxt mt10">
                              {{ item.questionsReleaseTime }}
                            </div>
                          </el-col>
                          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                            <div
                              class="grid-content userInfoTxt textRight mt10"
                            >
                              所属课程：{{ item.curriculumTheme }}
                            </div>
                          </el-col>
                        </el-row>
                        <!-- <div class="userName txtLeft">所属班级：{{item.curriculumTheme}}</div> -->
                        <div class="flex bottomCommentBox">
                          <el-badge
                            :value="item.curriculumQuestionReplyVoList.length"
                            class="commentBtnBox"
                          >
                            <el-button
                              size="small"
                              @click="showReplay(item, index)"
                              class="commentBtn"
                              >评论信息
                            </el-button>
                          </el-badge>
                          <el-button
                            size="small"
                            data-quesid="item.questionAnswerId"
                            @click="showTiQuesPop(item)"
                            class="replyBtn mr20"
                            >回复
                          </el-button>
                        </div>
                        <div
                          class="moreReplyBox"
                          v-if="
                            item.showCommentBox &&
                            item.curriculumQuestionReplyVoList &&
                            item.curriculumQuestionReplyVoList.length > 0
                          "
                        >
                          <div
                            class="mb10"
                            v-for="(
                              temp, idx
                            ) in item.curriculumQuestionReplyVoList"
                            :key="idx"
                          >
                            <div class="flex moreReplyCont">
                              <div
                                v-if="temp.memberUserName != null"
                                class="colorBlue ft12"
                              >
                                {{ temp.memberUserName }}
                              </div>
                              <div v-else class="colorBlue ft12">神秘用户</div>
                              <div
                                class="ft12 color4 ml24"
                                style="text-align: left"
                              >
                                {{ temp.replyDesc }}
                              </div>
                            </div>
                            <div
                              class="picsItemBox"
                              v-if="temp.pics != null && temp.pics.length > 0"
                            >
                              <img
                                v-for="(kemp, kempIndex) in temp.pics"
                                class="picsItem"
                                :src="kemp.questionPic"
                                :key="kempIndex"
                                @click="previewFile2(kemp.questionPic)"
                              />
                            </div>
                          </div>
                        </div>
                      </el-card>
                    </el-col>
                  </el-row>
                </div>
                <div v-else class="nodataTxt">- 暂无提问信息 -</div>
              </div>
            </template>
            <!-- 实验报告 -->
            <template v-if="currentTab == 5">
              <div class="experimentItemContainer" v-if="isShowReport">
                <div style="text-align: right">
                  <el-button
                    type="primary"
                    @click="exportReport()"
                    v-if="reportType == 0"
                    >导出实验报告</el-button
                  >
                </div>
                <div
                  style="
                    color: red;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <p>
                    <span v-if="isSubmitReport == 0">未提交</span>
                    <span v-else-if="isSubmitReport == 1">已提交</span>
                    <span v-else-if="isSubmitReport == 2">已驳回</span>
                    <span v-if="isMarkReport == 0">/未批阅</span>
                    <span v-else>/已批阅</span>
                  </p>
                  <p>
                    <span v-if="isMarkReport == 1"
                      >{{ experimentScore || 0 }}分</span
                    >
                  </p>
                </div>
                <div
                  class="homeworkImgList flex flex_wrap bg_gary"
                  style="margin: 0.1rem 0"
                  v-if="reportTemplate.nodeId != ''"
                >
                  <div
                    class="
                      coursewareItem
                      borderBox
                      flex_just_between flex_align_center
                    "
                    @click="previewFiles(reportTemplate)"
                  >
                    <template
                      v-if="getFileType(reportTemplate.nodePath) == 'image'"
                    >
                      <img class="iconPngFile" :src="reportTemplate.nodePath" />
                    </template>
                    <template v-else>
                      <img
                        class="iconFile"
                        v-if="
                          getFileType(reportTemplate.nodePath) == 'word' ||
                          getFileType(reportTemplate.nodePath) == '.docx'
                        "
                        src="../../assets/images/experiment/file_word.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          getFileType(reportTemplate.nodePath) == 'excel' ||
                          getFileType(reportTemplate.nodePath) == '.xlsx'
                        "
                        src="../../assets/images/experiment/file_xlsx.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          getFileType(reportTemplate.nodePath) == 'ppt' ||
                          getFileType(reportTemplate.nodePath) == '.pptx'
                        "
                        src="../../assets/images/experiment/file_ppt.png"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          getFileType(reportTemplate.nodePath) == 'pdf'
                        "
                        src="../../assets/images/experiment/file_pdf.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile"
                        v-else-if="
                          getFileType(reportTemplate.nodePath) == 'psd'
                        "
                        src="../../assets/images/experiment/psd.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile imgobj"
                        v-else-if="
                          getFileType(reportTemplate.nodePath) == 'video'
                        "
                        src="../../assets/images/experiment/mp4.png"
                        mode="widthFix"
                      />
                      <img
                        class="iconFile"
                        v-else
                        src="../../assets/images/experiment/file_none.png"
                        mode="widthFix"
                      />
                    </template>
                    <div class="flex_page flex_main">
                      <div class="coursewareTit txtOverFlow1">
                        {{ reportTemplate.nodeName }}
                      </div>
                    </div>
                    <img
                      class="iconLoad"
                      src="../../assets/images/experiment/ico_download.png"
                      alt=""
                      @click.stop="downLoadFile(reportTemplate)"
                    />
                  </div>
                </div>
                <div v-if="experimentStepList && experimentStepList.length > 0">
                  <div class="experimentName">
                    {{ experimentInfo.experimentName }}
                  </div>
                  <div class="experiContent" v-if="!showSubmitReport">
                    <div
                      v-for="(item, index) in experimentStepList"
                      :key="index"
                    >
                      <div
                        v-if="item.stepName != null && item.isQuestions == 0"
                      >
                        {{ item.stepName }}
                      </div>
                      <div v-if="item.isQuestions == 1" class="txtLeft">
                        思考题
                      </div>
                      <div
                        v-if="item.stepContent != null"
                        v-html="item.stepContent"
                      ></div>
                      <!--<div v-if="item.stepTable != null" v-html="item.stepTable"></div>-->
                      <div class="tableDiv">
                        <table class="tableBlock" v-if="item.stepTable">
                          <tbody>
                            <tr
                              class="trBlock"
                              v-for="(trItem, trIndex) in item.stepTable.r"
                              :key="trItem"
                            >
                              <td
                                class="tdBlock"
                                v-for="(tdItem, tdIndex) in item.stepTable.c"
                                :key="tdItem"
                              >
                                <div v-if="trIndex == 0">
                                  {{
                                    item.stepTable.data[trIndex + "," + tdIndex]
                                  }}
                                </div>
                                <div v-else>
                                  {{
                                    item.stepTable.data[trIndex + "," + tdIndex]
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="flex">
                      <!-- <el-button
                        class="submitBtn"
                        type="primary"
                        @click="searchShowCe()"
                        >查看指导手册</el-button
                      > -->
                      <el-button
                        v-if="isSubmitReport == 0"
                        class="submitBtn"
                        type="primary"
                        @click="searchReportInfo()"
                        >提交报告
                      </el-button>
                      <el-button
                        v-else
                        class="submitBtn"
                        type="primary"
                        @click="searchReportInfo()"
                        >查看报告</el-button
                      >
                    </div>
                  </div>
                  <div v-else class="showSubmitReportContainer">
                    <div v-if="experimentStepList.length > 0">
                      <!-- <div class="experimentName">{{experimentStepList[0].experimentName}}</div> -->
                      <!--不是问答题-->
                      <div v-if="experimentStepList[stepIdx].isQuestions == 0">
                        <div class="stepContent">
                          <div class="flex" style="align-items: center">
                            {{ stepIdx + 1 }}、
                            <span>{{
                              experimentStepList[stepIdx].stepName
                            }}</span>
                          </div>
                          <div
                            class="experDesc"
                            v-if="experimentStepList[stepIdx].stepContent"
                            v-html="experimentStepList[stepIdx].stepContent"
                          ></div>
                        </div>
                        <div class="content">
                          <div class="experimentalDesc">
                            <div class="title">实验描述:</div>
                            <el-input
                              type="textarea"
                              v-model="descVal"
                              :disabled="isSubmitReport == 1"
                              placeholder="填写描述内容…"
                            ></el-input>
                          </div>
                          <div class="experimentalDesc" v-if="tableVal">
                            <div class="title">实验表格:</div>
                            <div class="tableDiv">
                              <table class="tableBlock">
                                <tbody>
                                  <tr
                                    class="trBlock"
                                    v-for="(trItem, trIndex) in tableVal.r + 1"
                                    :key="trItem"
                                  >
                                    <td
                                      class="tdBlock"
                                      v-for="(tdItem, tdIndex) in tableVal.c"
                                      :key="tdItem"
                                    >
                                      <div v-if="trIndex == 0">
                                        {{
                                          tableVal.data[trIndex + "," + tdIndex]
                                        }}
                                      </div>
                                      <div v-else>
                                        <input
                                          type="text"
                                          v-model="
                                            tableVal.data[
                                              trIndex + ',' + tdIndex
                                            ]
                                          "
                                          :disabled="isSubmitReport == 1"
                                          placeholder="请输入内容"
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="experimentImgList bg_gary">
                            <div class="experimentReportTitle">上传附件:</div>

                            <el-upload
                              ref="uploadExcel"
                              action=""
                              :limit="6"
                              :data="uploadImgData"
                              :on-preview="handleFilePreview"
                              :http-request="beforeUploadFile"
                              :auto-upload="true"
                              multiple
                              :disabled="isSubmitReport == 1"
                              :on-remove="handleRemoveFile"
                              :file-list="fileSavedNameFileArr"
                            >
                              <el-button
                                size="small"
                                plain
                                v-if="isSubmitReport != 1"
                                >选择文件</el-button
                              >
                              <!-- <div slot="tip" class="el-upload__tip" v-if="isSubmitReport != 1">只能上传csv/xslx/xsl/txt文件</div> -->
                            </el-upload>
                          </div>
                        </div>
                        <div
                          class="fixedBttom flex box_shandow"
                          v-if="
                            stepIdx == 0 &&
                            stepIdx < experimentStepList.length - 1
                          "
                        >
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            >保存报告
                          </el-button>
                          <el-button type="primary" @click="nextStep"
                            >下一步</el-button
                          >
                        </div>
                        <div
                          class="fixedBttom flex box_shandow"
                          v-else-if="
                            stepIdx > 0 &&
                            stepIdx < experimentStepList.length - 1
                          "
                        >
                          <el-button type="primary" @click="upStep"
                            >上一步</el-button
                          >
                          <el-button
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            type="primary"
                            >保存报告
                          </el-button>
                          <el-button type="primary" @click="nextStep"
                            >下一步</el-button
                          >
                        </div>
                        <div class="fixedBttom flex box_shandow" v-else>
                          <el-button
                            class="bghui"
                            type="primary"
                            @click="upStep"
                            >上一步</el-button
                          >
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            >保存报告
                          </el-button>
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportSubmit"
                            >保存并提交
                          </el-button>
                        </div>
                      </div>
                      <div v-else>
                        <div class="content2">
                          <div class="stepBox">
                            <div class="stepItem">
                              <span style="float: left"
                                >{{ stepIdx + 1 }}、思考题</span
                              >
                              <div
                                class="contentTxt"
                                v-html="experimentStepList[stepIdx].stepContent"
                              ></div>
                            </div>
                          </div>
                          <div
                            class="experDesc"
                            v-html="experimentStepList[stepIdx].stepContent"
                          ></div>
                          <el-input
                            type="textarea"
                            v-model="descVal"
                            :disabled="isSubmitReport == 1"
                            placeholder="填写答案…"
                          ></el-input>

                          <div class="experimentImgList bg_gary">
                            <div class="experimentReportTitle">上传附件:</div>
                            <el-upload
                              ref="uploadExcel"
                              action=""
                              :limit="6"
                              :data="uploadImgData"
                              :on-preview="handleFilePreview"
                              :http-request="beforeUploadFile"
                              :auto-upload="true"
                              :disabled="isSubmitReport == 1"
                              multiple
                              :on-remove="handleRemoveFile"
                              :file-list="fileSavedNameFileArr"
                            >
                              <el-button
                                size="small"
                                plain
                                v-if="isSubmitReport != 1"
                                >选择文件</el-button
                              >
                              <!-- <div slot="tip" class="el-upload__tip" v-if="isSubmitReport != 1">只能上传csv/xslx/xsl/txt文件</div> -->
                            </el-upload>
                          </div>
                        </div>
                        <div
                          class="fixedBttom flex box_shandow"
                          v-if="
                            stepIdx == 0 &&
                            stepIdx < experimentStepList.length - 1
                          "
                        >
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            >保存报告
                          </el-button>
                          <el-button type="primary" @click="nextStep"
                            >下一步</el-button
                          >
                        </div>
                        <div
                          class="fixedBttom flex box_shandow"
                          v-else-if="
                            stepIdx > 0 &&
                            stepIdx < experimentStepList.length - 1
                          "
                        >
                          <el-button type="primary" @click="upStep"
                            >上一步</el-button
                          >
                          <el-button
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            type="primary"
                            >保存报告
                          </el-button>
                          <el-button type="primary" @click="nextStep"
                            >下一步</el-button
                          >
                        </div>
                        <div class="fixedBttom flex box_shandow" v-else>
                          <el-button
                            class="bghui"
                            type="primary"
                            @click="upStep"
                            >上一步</el-button
                          >
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportGoFun"
                            >保存报告
                          </el-button>
                          <el-button
                            type="primary"
                            :disabled="isSubmitReport == 1"
                            @click="saveReportSubmit"
                            >保存并提交
                          </el-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="experimentImgList bg_gary">
                    <div class="experimentReportTitle">上传附件:</div>
                    <el-upload
                      ref="uploadExcel"
                      action=""
                      :limit="6"
                      :data="uploadImgData"
                      :on-preview="handleFilePreview"
                      :http-request="beforeUploadFile"
                      :auto-upload="true"
                      multiple
                      :disabled="isSubmitReport == 1"
                      :on-remove="handleRemoveFile"
                      :file-list="fileSavedNameFileArr"
                    >
                      <el-button size="small" plain v-if="isSubmitReport != 1"
                        >选择文件</el-button
                      >
                      <!-- <div slot="tip" class="el-upload__tip" v-if="isSubmitReport != 1">只能上传csv/xslx/xsl/txt文件</div> -->
                    </el-upload>
                  </div>
                  <div class="fixedBttom flex box_shandow">
                    <el-button
                      type="primary"
                      :disabled="isSubmitReport == 1"
                      @click="saveReportGoFun"
                      >保存报告</el-button
                    >
                    <el-button
                      type="primary"
                      :disabled="isSubmitReport == 1"
                      @click="saveReportSubmit"
                      >保存并提交
                    </el-button>
                  </div>
                </div>
              </div>
              <div v-else class="nodataTxt">- 暂无实验报告信息 -</div>
            </template>
            <!-- 在线考试 -->
            <template v-if="currentTab == 6">
              <div v-if="examList.length > 0">
                <div
                  v-if="examList.length > 0 && isShowExamList"
                  class="flex flex_wrap bg_gary"
                >
                  <div
                    class="
                      coursewareItem
                      borderBox
                      flex_just_between flex_align_center
                    "
                    v-for="(item, index) in examList"
                    :key="index"
                    style="width: 100%"
                  >
                    <div style="font-size: 30px; padding-right: 10px">
                      <i class="el-icon-edit-outline"></i>
                    </div>
                    <div class="flex_page flex_main">
                      <div class="coursewareTit txtOverFlow1">
                        {{ item.papersName }}
                      </div>
                      <div class="coursewareTime">
                        考试开始时间：{{ item.examinationStartTime }}
                      </div>
                      <div class="coursewareTime">
                        考试结束时间：{{ item.examinationEndTime }}
                      </div>
                    </div>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="startExam(item.papersId)"
                      v-if="
                        item.answerNumber > item.practiceNum &&
                        item.isConduct == 1 &&
                        !item.userPapersId
                      "
                    >
                      开始考试
                    </el-button>
                    <el-button
                      type="primary"
                      icon="el-icon-edit"
                      @click="
                        getUserExamList(item.userPapersId, true, item.papersId)
                      "
                      v-if="
                        item.isConduct == 1 &&
                        item.isSubmitPaper == 0 &&
                        item.userPapersId
                      "
                    >
                      继续考试
                    </el-button>
                    <el-button
                      type="success"
                      icon="el-icon-view"
                      @click="
                        getUserExamList(item.userPapersId, false, item.papersId)
                      "
                      v-if="item.userPapersId != ''"
                      >查看考试
                    </el-button>
                  </div>
                </div>
              </div>
              <div v-else class="nodataTxt">- 暂无考试信息 -</div>
            </template>

            <!-- 课程直播 -->
            <template v-if="currentTab == 7">
              <div>
                <div v-if="LiveRoom != ''">
                  <div
                    style="padding: 20px; background: #f7f7f7"
                    v-for="(item, index) in LiveRoom"
                    :key="index"
                  >
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                      "
                    >
                      <div style="font-weight: bold; font-size: 15px">
                        {{ item.roomName || "" }}
                      </div>
                      <div style="display: flex; align-items: center">
                        <div style="padding-right: 15px">
                          {{
                            item.roomStatus == 0
                              ? "未开始"
                              : item.roomStatus == 1
                              ? "正在直播"
                              : "已结束"
                          }}
                        </div>
                        <el-button
                          type="primary"
                          size="medium"
                          @click="
                            liveRoomClick(item.liveRoomId, item.roomStatus)
                          "
                          >进入直播间</el-button
                        >
                      </div>
                    </div>
                    <div>
                      <span>创建时间：{{ item.createTime }}</span>
                    </div>
                  </div>
                </div>
                <div v-else class="nodataTxt">- 暂无直播信息 -</div>
                <!-- <el-card > -->

                <!-- </el-card> -->
              </div>
            </template>

            <!-- 实验设备 -->
            <template v-if="currentTab == 8">
              <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('oscilloscope')"
                    >示波器</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('signalSource')"
                    >信号源</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('avometers')"
                    >万用表</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('power')"
                    >电源</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('potierDiagram')"
                    >波特图</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('spectrumDiagram')"
                    >频谱图</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('editingAnalyzer')"
                    >逻辑分析仪</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('pulseGenerator')"
                    >脉冲信号发生器</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('staticInput')"
                    >静态输入</el-button
                  >
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getEqImg('staticOutput')"
                    >静态输出</el-button
                  >
                </el-form-item>
              </el-form>
              <div>
                <el-upload
                  class="uploadEqPic"
                  action=""
                  :on-preview="handleYMHardwarePicPreview"
                  :on-remove="handleYMHardwarePicRemove"
                  :file-list="eqPicList"
                  list-type="picture"
                >
                  <div slot="tip" class="el-upload__tip"></div>
                </el-upload>
              </div>
            </template>
          </div>
        </div>
        <div class="experimentModel" style="display: none">
          <div class="modelBox borderBox">
            <div class="modeTitle flex_align_center flex_just_between">
              <div>讨论区</div>
              <div class="color_999 more" @click="toQuesContent">
                <i class="el-icon-d-arrow-right"></i>
                查看更多
              </div>
            </div>
            <div class="modeContent">
              <div class="courseQuestionsMode">
                <div
                  v-for="(item, index) in quesList"
                  :key="index"
                  class="mode"
                >
                  <div class="flex">
                    <img
                      v-if="
                        item.headPortrait != null && item.headPortrait != ''
                      "
                      :src="item.headPortrait"
                      class="mineIcon"
                    />
                    <img
                      v-else
                      src="../../assets/images/experiment/noHeadIcon.jpg"
                      class="mineIcon"
                    />
                    <div class="flex_main">
                      <div class="flex_align_center flex_just_between">
                        <div class="fs14 fw600">
                          {{ item.memberUserName || "" }}
                        </div>
                        <div class="fs12 color_999">
                          {{ item.questionsReleaseTime }}
                        </div>
                      </div>
                      <div class="fs12 descp">{{ item.questionsDes }}</div>
                    </div>
                  </div>
                </div>
                <div class="courseQuesPage flex">
                  <div
                    :class="[quesPage == 1 ? 'disable' : '']"
                    @click="upQuesList"
                  >
                    <i class="el-icon-arrow-up"></i>
                  </div>
                  <div
                    :class="[quesPage == quesTotalPage ? 'disable' : '']"
                    @click="nextQuesList"
                  >
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择班级弹窗 -->
    <el-dialog
      title="选择班级信息"
      :visible.sync="collegeDialog"
      width="40%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <div class="max_H50vh">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="" label="选择" width="80">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row">{{
                "\xa0"
              }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column width="55" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="className" align="center" label="班级名称">
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog
      title="设备图片"
      :visible.sync="yMPicDialog"
      width="50%"
      :show-close="true"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-dialogDrag
    >
      <div class="max_H50vh">
        <img :src='ymPic' style="width:100%;height:100%">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="yMPicDialog=!yMPicDialog" >取消</el-button>
        <el-button type="primary" @click="downYmPIcFile(ymPic)" >下载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import quesPop from "../../components/quesPop.vue";
import courseFile from "./courseFile";
import AsingleFile from "@/utils/AsingleFile";
import getFileType from "@/utils/getFileType";
import $ from "jquery";
export default {
  name: "experimentInfoStuPage",
  components: {
    // "v-header": Header,
    courseFile: courseFile,
    quesPop: quesPop,
  },
  data() {
    return {
      getFileType,
      currentTab: 0,
      isShowReport: false,
      yMPicDialog: false,
      ymPic:'',
      collegeDialog: false,
      tableData: [],
      radio: {},
      reportType: 0,
      tabsList: [
        "课件下载",
        "课前预习",
        "在线练习",
        "实验作业",
        "课程提问",
        "实验报告",
        "在线考试",
        // "试验评价",
        "课程直播",
        "实验设备",
      ],
      papersId: "",
      isShowQuesContent: false,
      scrollTop: 0,
      token: localStorage.getItem("pc_token") ?? "",
      userInfo: JSON.parse(localStorage.getItem("pc_data")),
      curriculumResourceId: this.$route.query.id ?? "",
      experimentId: this.$route.query.experimentId ?? "", //实验id
      experimentInfo: { curriculumVideoList: [], experimentReport: {} }, //实验详情
      courseFileList: [], //课件列表
      homeworkList: [], //作业列表
      homeworkImgList: [], //上传作业图片列表
      homeworkFileList: [], //上传作业文件列表
      nodeIdImgs: [], //上传作业图片nodeId
      nodeIdFiles: [], //上传作业图片nodeId
      onlineData: { questionBanks: [] }, //在线答题
      courseQuesList: [], //在线提问
      quesPage: 1, //讨论区列表页码
      quesTotalPage: 1, //讨论区列表总页数
      quesTotal: 0, //讨论区列表个数
      quesList: [], //讨论区列表
      eqPicList: [], //设配图片
      showSubmitReport: false, //在线提问
      //实验报告
      tableVal: "", //表格内容的值
      descVal: "", //实验描述的值
      experimentStepList: [],
      reportInfo: null,
      curriculumHomeworkList: [], //作业
      stepIdx: 0,
      photo: "",
      userReportState: 0, // 0不提交-下一步保存，1提交-保存并提交
      percentVal: 0,
      disableSaveReportBtn: false, //按钮是否置灰
      nodeIdArr: [], //上传附件的nodeId
      nodeIdImageArr: [], //上传图片的nodeId
      nodeIdFileArr: [], //上传文件的nodeId
      userReportFileList: [], //上传的图片
      fileSavedNameFileArr: [], //上传的文件
      filePaths: [],
      hwFilePaths: [],
      fileSavedNameArr: [], //上传的图片名
      fileSavedNameFiles: [], //上传的文件名
      dialogImageUrl: "",
      eqUrl: "",
      dialogVisible: false,
      uploadHomeworkData: {
        relative: "homeworkFile",
        appCode: "homeworkFile",
      },
      uploadImgData: {
        /*file: tempFilePaths[0],*/
        relative: "userReportFile",
        appCode: "userReportFile",
      },
      memberName_search: "", //输入会员名搜索问答
      isSubmitOnline: false,
      checkedOpttions: [1, 1],
      quesForm: [],
      isSubmitReport: 0,
      isMarkReport: 0,
      experimentScore: 0,
      isSubmitHomework: false,

      //练习
      practiceList: [],
      practiceParamList: [],
      practiceInfo: {},
      isPracticePwd: false,
      isShowPracticeParams: false, //是否显示字段
      isShowPracticeList: true, //是否显示练习列表
      isShowPracticeContent: false, //是否显示练习题目
      practiceContentList: [], //练习题目
      practiceArray: [], //练习题目
      practiceBankList: [], //练习题目List
      practiceQuesForm: [], //练习选项
      practiceUserPapers: {}, //会员练习选项
      isShowPracticeBankAll: false, //是否显示所有试题
      isPracticeLast: false, //是否最后一题
      isPracticeFirst: false, //是否第一题
      practiceBankCount: 0, //练习题目数量
      currPracticeBankNumber: 0, //当前练习题数量
      isShowPractice: false, //是否展示
      isSubmitPaper: false, //是否提交
      //试卷
      examList: [],
      examParamList: [],
      examInfo: {},
      isExamPwd: false,
      isShowExamParams: false, //是否显示字段
      isShowExamList: true, //是否显示考试列表
      isShowExamContent: false, //是否显示考试题目
      examContentList: [], //考试题目
      examArray: [], //考试题目
      examBankList: [], //考试题目List
      examQuesForm: [], //考试选项
      examUserPapers: {}, //会员考试选项
      isShowExamBankAll: false, //是否显示所有试题
      isExamLast: false, //是否最后一题
      isExamFirst: false, //是否第一题
      examBankCount: 0, //考试题目数量
      currExamBankNumber: 0, //当前考试题数量
      isShowExam: false, //是否展示
      isSubmitExam: false, //是否提交
      isSubmitPrac: true,

      videoArr: [],
      videoArr1: [],
      curriculumVideoLists: [],
      isInterval: false,
      videoUrl: "", //视频链接
      LiveRoom: [],
      isHas: "",
      preGuidance: [],
      activeNames: ["1"],
      noticeList: {},
      reportTemplate: {
        nodeId: "",
      },
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },
  created() {
    // if (!this.token) {
    //   this.$router.push({
    //     path: "/login",
    //   });
    // }
  },
  mounted() {
    this.findVideoInfo();
    this.getClassRoom();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    submit() {
      let classId = this.radio.classId ?? "";
      if (classId == "") {
        this.$message.error("请选择班级！");
        return false;
      } else {
        this.collegeDialog = false;
      }
    },
    handleClose() {
      this.collegeDialog = false;
    },
    getClassRoom() {
      if (this.token != "") {
        this.$api
          .getExperimentJoinClassList({
            token: this.token,
            experimentId: this.experimentId,
            curriculumResourceId: this.curriculumResourceId,
          })
          .then((res) => {
            this.tableData = res.data ?? [];
            if (this.tableData.length == 0) {
              this.$message.error("请先加入实验！");
              //  window.history.back();
              this.$router.go(-1);
            } else if (this.tableData.length == 1) {
              this.radio = this.tableData[0];
              this.getExperimentInfo();
            } else {
              this.collegeDialog = true;
              this.getExperimentInfo();
            }
          });
      } else {
        this.getExperimentInfo();
      }
    },
    //回复展示
    showReplay(item, index) {
      let _showCommentBox = item.showCommentBox ?? false;
      if (_showCommentBox) {
        item.showCommentBox = false;
      } else {
        item.showCommentBox = true;
      }
      this.$set(this.courseQuesList, index, item);
    },
    previewFiles(item) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      AsingleFile(item.nodePath);
    },
    aaa(url) {
      this.videoUrl = url;
      document.getElementById("video").load();
      console.log(this.videoUrl);
    },
    // 进入直播间
    liveRoomClick(liveRoomId, roomStatus) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      // if(roomStatus == 0){
      //   this.$message.warning('直播暂未开始')
      //   return
      // }else if(roomStatus == 2){
      //   this.$message.warning('直播已结束')
      //   return
      // }
      this.$router.push({
        path: "/videoplayerlive",
        query: { id: liveRoomId },
      });
    },
    videoplay(e, val) {},
    // 视频观看信息
    recordTimeTexrt(val) {
      let text = "";
      this.videoArr1.forEach((item) => {
        if (val.videoId == item.videoId) {
          text = item.recordTime.toFixed();
          setTimeout(() => {
            this.$refs[val.videoId][0].currentTime = text;
          }, 200);
        }
      });

      function getTime(time) {
        // 转换为式分秒
        let h = parseInt((time / 60 / 60) % 24);
        h = h < 10 ? "0" + h : h;
        let m = parseInt((time / 60) % 60);
        m = m < 10 ? "0" + m : m;
        let s = parseInt(time % 60);
        s = s < 10 ? "0" + s : s;
        // 作为返回值返回
        return [h, m, s];
      }
      // 传入用户输入的数据
      let res = getTime(text);

      return `上次观看实时间${res.join(":")}`;
    },
    // 根据当前会员和实验查询保存的视频观看信息
    findVideoInfo() {
      if (this.token == "") {
        return false;
      }
      const that = this;
      this.$api
        .findCurriculumExperimentLookVideoByToken({
          token: that.token,
          experimentId: that.$route.query.experimentId,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.videoArr1 = JSON.parse(res.data.videoData);
          }
        });
    },
    // 保存当前会员和实验查询保存的视频观看信息
    videoupdate(e, val) {
      val.videoId;
      const flag = this.videoArr.every((item) => item.videoId !== val.videoId);
      if (flag) {
        this.videoArr.push({
          videoId: val.videoId,
          recordTime: e.target.currentTime,
        });
      } else {
        const index = this.videoArr.findIndex(
          (item) => item.videoId === val.videoId
        );
        const obj = {
          videoId: val.videoId,
          recordTime: e.target.currentTime,
        };
        this.videoArr.splice(index, 1, obj);
      }

      if (this.isInterval) {
        return;
      }
      setTimeout(() => {
        this.isInterval = false;
        if (this.token != "") {
          const that = this;
          this.$api
            .saveCurriculumExperimentLookVideoByToken({
              token: that.token,
              experimentId: that.$route.query.experimentId,
              videoData: JSON.stringify(this.videoArr),
            })
            .then((res) => {});
        }
      }, 10000);
      this.isInterval = true;
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    backPage() {
      window.history.back();
    },
    changeTab(index) {
      this.currentTab = index;
      if (index == 0) {
        //课件下载
        this.enableEdit();
        this.getCourseFileList();
      } else if (index == 1) {
        //课前预习
        this.getFrontCoursePreGuidance();
      } else if (index == 2) {
        //在线练习
        this.enableEdit();
        //获取实验练习信息
        this.getExperimentPractice();
      } else if (index == 3) {
        //实验作业
        this.enableEdit();
        this.getCourseHomeworkList();
      } else if (index == 4) {
        //课程提问
        this.enableEdit();
        this.getCourseQuesList();
      } else if (index == 5) {
        //实验报告
        this.enableEdit();
        this.searchReportInfo();
      } else if (index == 6) {
        //在线考试
        this.enableEdit();
        this.getExperimentExam();
      } else if (index == 7) {
        //课程直播
        this.getSxCurriculumLiveRoom();
      } else if (index == 8) {
        //实验设备
        this.enableEdit();
        this.initExperimentEquipmentPic();
      }
    },

    //初始化实验设备图片
    initExperimentEquipmentPic() {
      let that = this;
      this.$api
        .getExperimentEquipmentPicPage({
          token: that.token,
          experimentId: that.experimentId,
        })
        .then((res) => {
          if (res.code == 10000) {
            let resData = res.data;
            if (resData && resData.length > 0) {
              let equipmentPicList = [];
              for (let k = 0; k < resData.length; k++) {
                equipmentPicList.push({
                  name: resData[k].picName,
                  url: resData[k].picPath,
                  picId: resData[k].equipmentPicId,
                });
              }
              that.eqPicList = equipmentPicList;
            }
          }
        });
    },
    convertImgToBase64(url, callback, outputFormat, callbackOne) {
      let canvas = document.createElement("CANVAS");
      let ctx = canvas.getContext("2d");
      let img = new Image();
      img.crossOrigin = "Anonymous";

      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);

        let dataURL = canvas.toDataURL(outputFormat || "image/png");
        callback.call(this, dataURL);
        canvas = null;
      };
      img.onerror = function () {
        callback.call(this, "");
      };
      img.src = url;
    },
    getEqImg(equipmentType) {
      let that = this;
      let localIp = "127.0.0.1";
      let eqFileName = "";
      if ("oscilloscope" == equipmentType) {
        //示波器
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/ds_screenshot";
        eqFileName = "示波器";
      } else if ("signalSource" == equipmentType) {
        //信号源
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dg_screenshot";
        eqFileName = "信号源";
      } else if ("avometers" == equipmentType) {
        //万用表
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dMM_screenshot";
        eqFileName = "万用表";
      } else if ("power" == equipmentType) {
        //电源
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dp_screenshot";
        eqFileName = "电源";
      } else if ("potierDiagram" == equipmentType) {
        //波特图
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/db_screenshot";
        eqFileName = "波特图";
      } else if ("spectrumDiagram" == equipmentType) {
        //频谱图
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/df_screenshot";
        eqFileName = "频谱图";
      } else if ("editingAnalyzer" == equipmentType) {
        //辑分析仪
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dLI_screenshot";
        eqFileName = "辑分析仪";
      } else if ("pulseGenerator" == equipmentType) {
        //脉冲信号发生器
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dLO_screenshot";
        eqFileName = "脉冲信号发生器";
      } else if ("staticInput" == equipmentType) {
        //静态输入
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dSI_screenshot";
        eqFileName = "静态输入";
      } else if ("staticOutput" == equipmentType) {
        //静态输出
        that.eqUrl = "http://" + localIp + ":8002/EMOOC/dSO_screenshot";
        eqFileName = "静态输出";
      }
      //获取设备序列号
      let hardwareId = "";
      that.$Global.axiosUrlNoBaseUrl(
        that.$http.getHardwareId,
        "get",
        false,
        that.$qs.stringify({}),
        (res) => {
          console.log("获取设备序列号", res);
          let resData = res.data || "";
          if ("" != resData) {
            hardwareId = resData.hardware_id;
            if ("" == hardwareId) {
              this.$message(
                "请连接硬木课堂实验平台并开启Electronics Pioneer软件！"
              );
            }
          }
        }
      );
      let _base64Img = "";
      that.convertImgToBase64(that.eqUrl, function (base64Img) {
        // console.log('IMAGE:',base64Img);
        if ("" == base64Img) {
          that.$message(
            "没有仪器界面或是连接仪器,请连接硬木课堂实验平台并开启Electronics Pioneer软件！"
          );
          return false;
        } else {
          _base64Img = base64Img;
          _base64Img = _base64Img.substr(22, _base64Img.length);
        }
        console.log("base64文件流：", _base64Img);

        that.$api
          .getStsToken({
            channelId: that.$http.channelId,
          })
          .then((res) => {
            if (res.code == 10000) {
              let isOss = res.data.isOss;
              let reData = res.data;
              //本地上传
              if ("0" == isOss) {
                that.$api
                  .uploadBase64Img({
                    relative: "YMHardwarePic",
                    appCode: "YMHardwarePic",
                    sourceName:
                      that.experimentInfo.experimentName +
                      "_" +
                      eqFileName +
                      (that.eqPicList.length + 1) +
                      ".png",
                    fileStr: _base64Img,
                  })
                  .then((re) => {
                    console.log("返回数据", re);
                    let resData = re || "";
                    that.$api
                      .addExperimentEquipmentPic({
                        token: that.token,
                        experimentId: that.experimentInfo.experimentId,
                        fileName: resData.originalFileName,
                        fileUrl: resData.filePreviewPathFull,
                        displaySort: that.eqPicList.length,
                      })
                      .then((r) => {
                        console.log("返回数据设备图片", r);
                        that.eqPicList.unshift({
                          name: resData.originalFileName,
                          url: resData.filePreviewPathFull,
                          picId: r.data || "",
                        });
                      });
                  });
              } else {
                const OSS = require("ali-oss");
                let folder = "YMHardwarePic";
                const client = new OSS({
                  accessKeyId: reData.accessKeyId,
                  accessKeySecret: reData.accessKeySecret,
                  stsToken: reData.securityToken,
                  region: reData.regionId,
                  bucket: reData.bucket,
                });
                let file = that.dataURLtoFile(base64Img);
                let d = new Date();
                let year = d.getFullYear();
                let month = d.getMonth() + 1;
                let day = d.getDate();
                let savePath = folder + "/" + year + "/" + month + day + "/";
                let size = file.size;
                let fileName = file.name;
                let index1 = fileName.lastIndexOf(".");
                let fileExt = fileName.substring(index1);
                //不带点
                let fileExt2 = fileName.substring(index1 + 1);
                let code = "";
                for (let i = 0; i < 6; i++) {
                  let radom = Math.floor(Math.random() * 10);
                  code += radom;
                }
                let saveName = d.getTime() + code + fileExt;
                let storeAs = savePath + saveName;
                const data = file;

                async function putObject() {
                  try {
                    // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                    // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
                    // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
                    const result = await client.put(storeAs, data);
                    if ("200" == result.res.statusCode) {
                      let imgUrl = result.res.requestUrls[0];
                      let filePreviewPathFull = imgUrl.split("?")[0];

                      that.$api
                        .addExperimentEquipmentPic({
                          token: that.token,
                          experimentId: that.experimentInfo.experimentId,
                          fileName:
                            that.experimentInfo.experimentName +
                            "_" +
                            eqFileName +
                            (that.eqPicList.length + 1) +
                            ".png",
                          fileUrl: filePreviewPathFull,
                          displaySort: that.eqPicList.length,
                        })
                        .then((r) => {
                          console.log("返回数据设备图片", r);
                          that.eqPicList.unshift({
                            name:
                              that.experimentInfo.experimentName +
                              "_" +
                              eqFileName +
                              (that.eqPicList.length + 1) +
                              ".png",
                            url: filePreviewPathFull,
                            picId: r.data || "",
                          });
                        });
                    } else {
                      that.$message("上传失败");
                    }
                  } catch (e) {
                    that.$message("上传失败");
                    console.log(e);
                  }
                }

                putObject();
              }
            }
          });
      });
    },
    //将base64格式图片转换为文件形式
    dataURLtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, { type: mime });
    },
    downYmPIcFile(url){
      window.open(url);
    },
    //硬木设备图片预览
    handleYMHardwarePicPreview(file) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      this.ymPic = file.url;
      this.yMPicDialog =true
      // this.$alert(
      //   '<img src="' + imgurl + '" style="width:100%;height:100%">',
      //   "预览图片",
      //   {
      //     dangerouslyUseHTMLString: true,
      //   }
      // );
    },
    //硬木设备图片删除
    handleYMHardwarePicRemove(file, eqPicList) {
      let that = this;
      console.log(file, eqPicList);
      this.$api
        .delExperimentEquipmentPic({
          token: that.token,
          equipmentPicId: file.picId,
        })
        .then((res) => {
          if (res.code == 10000) {
            that.$message("删除实验设备图片成功！");
          }
        });

      let index = that.eqPicList.findIndex((item) => file.url == file.url);
      that.eqPicList.splice(index, 1);
    },
    // 清空搜索内容
    clearSearch() {
      this.memberName_search = "";
      this.getCourseQuesList();
    },

    // 课前预习
    getFrontCoursePreGuidance() {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let courseId = this.experimentInfo.courseId;
      let that = this;
      let classId = this.radio.classId ?? "";

      // if(classId == ''){
      //      this.getClassRoom()
      //      return false
      // }
      //获取实验考试
      this.$api
        .getFrontCoursePreGuidance({
          token: this.token,
          experimentId: that.experimentId,
          courseId: courseId,
          classId: classId,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            console.log(res.data);
            that.preGuidance = res.data ?? "";
            this.isHas = res.data.isHas;
            this.noticeList = res.data.notice ?? [];
          }
        });
    },
    // 课程直播
    getSxCurriculumLiveRoom() {
      let that = this;
      let courseId = this.experimentInfo.courseId;
      this.$api
        .getFrontCurriculumLiveRoomListApi({
          token: this.token,
          courseId: courseId,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            that.LiveRoom = res.data ?? "";
          }
        });
    },
    //查询学生课程详情
    getExperimentInfo() {
      let that = this;

      this.$api
        .getClientCurriculumExperimentInfoApi({
          token: this.token,
          experimentId: this.$route.query.experimentId,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            that.experimentInfo = res.data ?? {};
            let _xzx = res.data.curriculumVideoList ?? [];
            if (_xzx.length != 0) {
              that.curriculumVideoLists = res.data.curriculumVideoList[0];
              that.videoUrl = that.curriculumVideoLists.videoUrl;
            }

            if (that.experimentInfo.curriculumHomeworkList) {
              let list = that.experimentInfo.curriculumHomeworkList;
              list.map((item) => {
                if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.nodeName)) {
                  item.isImage = true;
                } else {
                  item.isImage = false;
                  var index1 = item.nodeName.lastIndexOf(".");
                  var index2 = item.nodeName.length;
                  var type = item.nodeName.substring(index1, index2);
                  item.typeName = type.toLowerCase();
                }
              });
              that.curriculumHomeworkList = list;
            }
            that.getCourseFileList();
          }
        });
    },
    nextQuesList() {
      if (this.quesPage != this.quesTotalPage) {
        this.quesPage++;
        this.getQuesList();
      }
    },
    upQuesList() {
      if (this.quesPage != 1) {
        this.quesPage--;
        this.getQuesList();
      }
    },
    // 查询讨论区
    getQuesList() {
      let that = this;

      this.$api
        .queryCurriculumQuestionAnswerList({
          token: this.token,
          channelId: this.$http.channelId,
          curriculumResourceId: this.$route.query.id,
          // curriculumTheme: that.experimentInfo.curriculumTheme,
          page: that.quesPage,
          limit: 5,
        })
        .then((res) => {
          if (res.code == 10000) {
            let resData = res.data.data ?? [];
            if (resData != null && resData.length > 0) {
              let count = resData.count;
              that.quesTotalPage = resData.pages;
              that.quesList = resData;
              for (let i = 0; i < resData.length; i++) {
                resData[i].showCommentBox = false;
              }
              that.courseQuesList = resData;
            } else {
              that.courseQuesList = [];
            }
          }
        });
    },
    toQuesContent() {
      this.isShowQuesContent = true;
      this.getCourseQuesList();
    },
    hideQuesContent() {
      this.isShowQuesContent = false;
    },
    //查询课件列表
    getCourseFileList() {
      let that = this;

      this.$api
        .getClientCurriculumCoursewareListApi({
          token: this.token,
          experimentId: this.$route.query.experimentId,
          curriculumResourceId: this.$route.query.id,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            let list = res.data;
            list.map((item) => {
              if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.nodeName)) {
                item.isImage = true;
              } else {
                item.isImage = false;
                var index1 = item.nodeName.lastIndexOf(".");
                var index2 = item.nodeName.length;
                var type = item.nodeName.substring(index1, index2);
                item.typeName = type.toLowerCase();
              }
            });

            that.courseFileList = list;
            console.log("课件目录：", that.courseFileList);
          }
        });
    },
    //查询课件作业
    getCourseHomeworkList() {
      let that = this;
      this.$api
        .getClientCurriculumUserHomeworkListApi({
          token: this.token,
          chapterId: this.$route.query.experimentId,
          curriculumResourceId: this.$route.query.id,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            let resData = res.data.data;
            if (resData.length > 0) {
              resData.map((temp) => {
                temp.homeworkImgList = [];
                temp.homeworkFileList = [];
                temp.subMap.map((item) => {
                  if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(item.nodeName)) {
                    item.isImage = true;
                    temp.homeworkImgList.push({
                      name: item.nodeName,
                      url: item.nodePath,
                    });
                    temp.homeworkFileList.push({
                      name: item.nodeName,
                      url: item.nodePath,
                    });
                  } else {
                    item.isImage = false;
                    temp.homeworkFileList.push({
                      name: item.nodeName,
                      url: item.nodePath,
                    });
                  }
                });
              });
            }
            this.homeworkList = resData;
            console.log("homeworkList", this.homeworkList);
          }
        });
    },

    // 上传作业
    updateUserHomework() {
      let that = this;
      let nodeIdArr = this.nodeIdImgs.concat(this.nodeIdFiles);
      let homeworkNodeArr = [];
      nodeIdArr.map((item) => {
        homeworkNodeArr.push({
          nodeId: item.nodeId,
        });
      });
      console.log(homeworkNodeArr);
      if (homeworkNodeArr.length == 0) {
        that.$message({
          message: "请上传作业附件",
          type: "warning",
        });
        return;
      } else {
        this.$api
          .updateUserHomework({
            curriculumResourceId: that.curriculumResourceId,
            homeworkName: that.experimentInfo.experimentName,
            experimentId: that.experimentId,
            token: this.token,
            homeworkNodeStr: JSON.stringify(homeworkNodeArr),
          })
          .then((res) => {
            that.$message({
              message: "上传成功",
              type: "success",
            });
            that.getCourseHomeworkList();
          });
      }
    },
    //下载课件
    downLoadFile(item) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let that = this;
      this.$api
        .getStsToken({
          channelId: this.$http.channelId,
        })
        .then((res) => {
          let isOss = res.data.isOss;
          let reData = res.data;
          //本地上传
          if ("0" == isOss) {
            window.location.href =
              this.$http.baseUrl +
              "/file/file/api/download?route=file&nodeId=" +
              item.nodeId;
          } else {
            const OSS = require("ali-oss");
            const client = new OSS({
              accessKeyId: reData.accessKeyId,
              accessKeySecret: reData.accessKeySecret,
              stsToken: reData.securityToken,
              region: reData.regionId,
              bucket: reData.bucket,
            });
            this.$api
              .getNodeInfo({
                nodeId: item.nodeId,
              })
              .then((rs) => {
                let objectName = rs.data.serviceId;
                let path = rs.data.path;
                // console.log("===========",res)
                if (path.indexOf("http") != -1) {
                  // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
                  const filename = rs.data.nodeName; // 自定义下载后的文件名。
                  const response = {
                    "content-disposition": `attachment; filename=${encodeURIComponent(
                      filename
                    )}`,
                  };
                  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                  const url = client.signatureUrl(objectName, { response });
                  window.location.href = url;
                } else {
                  window.location.href =
                    this.$http.baseUrl +
                    "/file/file/api/download?route=file&nodeId=" +
                    item.nodeId;
                }
              });
          }
        });
    },
    //预览课件
    previewFile(item) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      AsingleFile(item.nodePath);
    },
    //预览课件
    previewFile2(imgurl) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      this.$alert(
        '<img src="' + imgurl + '" style="width: 100%;">',
        "预览图片",
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
    //查看指导手册
    searchShowCe() {
      let path = this.experimentInfo.path;
      if (path.indexOf("http://47.103.56.203") != -1) {
        path = path.replace("http://47.103.56.203", this.$http.baseUrl);
      }
      window.open(path);
      // window.open("https://view.officeapps.live.com/op/view.aspx?src="+newPath)
    },
    //获取文件后缀
    getType: function (file) {
      var filename = file;
      var index1 = filename.lastIndexOf(".");
      var index2 = filename.length;
      var type = filename.substring(index1, index2);
      return type;
    },
    keydown() {
      console.log(this.memberName_search);
      this.getCourseQuesList();
    },
    //查询课程提问
    getCourseQuesList() {
      let that = this;

      this.$api
        .queryCurriculumQuestionAnswerList({
          token: this.token,
          channelId: this.$http.channelId,
          curriculumResourceId: this.$route.query.id,
          // curriculumTheme: that.experimentInfo.curriculumTheme,
          page: that.quesPage,
          userName: that.memberName_search,
          limit: 5,
        })
        .then((res) => {
          if (res.code == 10000) {
            let resData = res.data.data ?? [];
            if (resData != null && resData.length > 0) {
              let count = resData.count;
              that.quesTotalPage = resData.pages;
              that.quesList = resData;
            } else {
              that.courseQuesList = [];
            }
          }
          let resData = res.data.data ?? [];
          if (resData != null && resData.length > 0) {
            for (let i = 0; i < resData.length; i++) {
              resData[i].showCommentBox = false;
            }
            that.courseQuesList = resData;
          } else {
            that.courseQuesList = [];
          }
        });
    },
    //弹框课程提问
    showTiQuesPop: function (item) {
      console.log(item);
      let that = this;
      // let width = this.isMobile ? "85%" : "5rem";
      // let height = this.isMobile ? "4rem" : "3.1rem";
      let content = {
        content: quesPop, //传递的组件对象
        parent: this, //当前的vue对象
        data: {
          curriculumResourceId: that.curriculumResourceId,
          questionAnswerId: item != "" ? item.questionAnswerId : "",
        },
      };
      this.$layer.iframe({
        content: content,
        area: ["740px", "475px"],
        title: item != "" ? "回复" : "我有话要说",
        offset: "auto",
        icon: 0,
        shade: true, //是否显示遮罩
        tipsMore: false, //是否允许多个tips
        shadeClose: true, //点击遮罩是否关闭
        success: function () {},
      });
    },
    //查询实验报告详情
    searchReportInfo: function () {
      this.showSubmitReport = true;
      this.fileSavedNameFileArr = [];
      let that = this;
      this.$api
        .queryExperimentReportInfo({
          experimentId: this.$route.query.experimentId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            if (res.data) {
              let data = res.data;
              let _reportType = data.reportType;
              this.reportType = _reportType;
              if (_reportType == 0) {
                let _reportStepList = data.reportStepList || [];
                if (_reportStepList.length == 0) {
                  return;
                }
              }
              //实验报告模板
              if (_reportType == 1) {
                let _nodeId = data.nodeId ?? "";
                if (_nodeId != "") {
                  this.reportTemplate = {
                    nodeId: data.nodeId,
                    nodeName: data.nodeName,
                    nodePath: data.nodePath,
                  };
                }
              }
              this.isShowReport = true;
              let stepIdx = that.stepIdx;
              let fileSavedNameArr = [];
              let nodeIdArr = [];
              let nodeIdImageArr = [];
              let nodeIdFileArr = [];
              let imgHzArr = [
                ".png",
                ".jpg",
                ".jpeg",
                ".gif",
                ".PNG",
                ".JPG",
                ".JPEG",
                ".GIF",
              ];
              that.userReportFileList = [];
              that.fileSavedNameFiles = [];
              that.reportInfo = data;
              if (data.reportStepList && data.reportStepList.length > 0) {
                if (data.reportStepList[stepIdx]) {
                  if (data.reportStepList[stepIdx].stepTable) {
                    if (that.isJSON(data.reportStepList[stepIdx].stepTable)) {
                      that.tableVal = JSON.parse(
                        data.reportStepList[stepIdx].stepTable
                      );
                    } else {
                      that.tableVal = "";
                    }
                  } else {
                    that.tableVal = "";
                  }
                  if (data.reportStepList[stepIdx].userReportStep) {
                    that.stepIdx = stepIdx;
                    that.descVal =
                      data.reportStepList[
                        stepIdx
                      ].userReportStep.experimentalDescription;
                    if (
                      data.reportStepList[stepIdx].userReportStep
                        .experimentTable
                    ) {
                      if (
                        that.isJSON(
                          data.reportStepList[stepIdx].userReportStep
                            .experimentTable
                        )
                      ) {
                        that.tableVal = JSON.parse(
                          data.reportStepList[stepIdx].userReportStep
                            .experimentTable
                        );
                      } else {
                        that.tableVal = "";
                      }
                    } else {
                      that.tableVal = "";
                    }
                    let userReportFileList =
                      data.reportStepList[stepIdx].userReportStep != null
                        ? data.reportStepList[stepIdx].userReportStep
                            .userReportFile
                        : [];
                    if (userReportFileList && userReportFileList.length > 0) {
                      for (let i = 0; i < userReportFileList.length; i++) {
                        let obj = {
                          name: userReportFileList[i].fileSavedName,
                          url: userReportFileList[i].filePath,
                        };
                        that.userReportFileList.push(obj);
                        that.fileSavedNameFileArr.push(obj);
                        that.fileSavedNameFiles.push(obj.name);
                        if (userReportFileList[i].fileSavedName != null) {
                          if (
                            imgHzArr.indexOf(
                              that.getType(userReportFileList[i].fileSavedName)
                            ) >= 0
                          ) {
                            // 图片列表
                            fileSavedNameArr.push(
                              userReportFileList[i].fileSavedName
                            );

                            nodeIdImageArr.push(userReportFileList[i].nodeId);
                          } else {
                            nodeIdFileArr.push(userReportFileList[i].nodeId);
                          }
                        }
                        nodeIdArr.push(userReportFileList[i].nodeId);
                      }
                      that.descVal =
                        data.reportStepList[stepIdx].userReportStep != null
                          ? data.reportStepList[stepIdx].userReportStep
                              .experimentalDescription
                          : "";
                      if (data.reportStepList[stepIdx].userReportStep) {
                        if (
                          that.isJSON(
                            data.reportStepList[stepIdx].userReportStep
                              .experimentTable
                          )
                        ) {
                          that.tableVal = JSON.parse(
                            data.reportStepList[stepIdx].userReportStep
                              .experimentTable
                          );
                        } else {
                          that.tableVal = "";
                        }
                      } else {
                        that.tableVal = "";
                      }
                      that.photo =
                        data.reportStepList[stepIdx].userReportStep != null
                          ? data.reportStepList[stepIdx].userReportStep.photo
                          : "";
                    }
                  }
                }
              }
              if (data.userReport) {
                that.isSubmitReport = data.userReport.userReportState;
                if (data.userReport.userReportState == 1) {
                  that.disableSaveReportBtn = true;
                } else {
                  that.disableSaveReportBtn = false;
                }
                if (data.userReport.isMark == 1) {
                  that.isMarkReport = 1;
                  that.experimentScore = data.userReport.experimentScore;
                } else {
                  that.isMarkReport = 0;
                  that.experimentScore = 0;
                }
                if (
                  data.userReport.userReportFile &&
                  data.userReport.userReportFile.length > 0
                ) {
                  for (
                    let i = 0;
                    i < data.userReport.userReportFile.length;
                    i++
                  ) {
                    let obj = {
                      name: data.userReport.userReportFile[i].fileSavedName,
                      url: data.userReport.userReportFile[i].filePath,
                    };
                    that.userReportFileList.push(obj);
                    that.fileSavedNameFileArr.push(obj);
                    that.fileSavedNameFiles.push(obj.name);
                    if (
                      data.userReport.userReportFile[i].fileSavedName != null
                    ) {
                      if (
                        imgHzArr.indexOf(
                          that.getType(
                            data.userReport.userReportFile[i].fileSavedName
                          )
                        ) >= 0
                      ) {
                        // 图片列表
                        fileSavedNameArr.push(
                          data.userReport.userReportFile[i].fileSavedName
                        );

                        nodeIdImageArr.push(
                          data.userReport.userReportFile[i].nodeId
                        );
                      } else {
                        nodeIdFileArr.push(
                          data.userReport.userReportFile[i].nodeId
                        );
                      }
                    }
                    nodeIdArr.push(data.userReport.userReportFile[i].nodeId);
                  }

                  that.photo =
                    data.userReport.userReportFile.photo != null
                      ? data.userReport.userReportFile.photo
                      : "";
                }
              } else {
                that.isSubmitReport = 0;
                that.isMarkReport = 0;
                that.experimentScore = 0;
                that.disableSaveReportBtn = false;
              }
              that.nodeIdArr = nodeIdArr;
              that.nodeIdFileArr = nodeIdFileArr;
              that.nodeIdImageArr = nodeIdImageArr;
              that.fileSavedNameArr = fileSavedNameArr;
              that.experimentStepList = data.reportStepList;
              if (
                that.experimentStepList &&
                that.experimentStepList.length > 0
              ) {
                for (var i = 0; i < that.experimentStepList.length; i++) {
                  if (that.experimentStepList[i].stepTable) {
                    if (that.isJSON(that.experimentStepList[i].stepTable)) {
                      that.experimentStepList[i].stepTable = JSON.parse(
                        that.experimentStepList[i].stepTable
                      );
                    } else {
                      that.experimentStepList[i].stepTable = "";
                    }
                  } else {
                    that.experimentStepList[i].stepTable = "";
                  }
                  if (that.experimentStepList[i].userReportStep) {
                    if (
                      that.experimentStepList[i].userReportStep.experimentTable
                    ) {
                      if (
                        that.isJSON(
                          that.experimentStepList[i].userReportStep
                            .experimentTable
                        )
                      ) {
                        that.experimentStepList[
                          i
                        ].userReportStep.experimentTable = JSON.parse(
                          that.experimentStepList[i].userReportStep
                            .experimentTable
                        );
                      } else {
                        that.experimentStepList[
                          i
                        ].userReportStep.experimentTable = "";
                      }
                    } else {
                      that.experimentStepList[
                        i
                      ].userReportStep.experimentTable = "";
                    }
                  }
                }
              }
              that.percentVal = 100;
            }
          }
        });
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == "object" && obj) {
            return true;
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }
    },
    //下一步
    nextStep: function () {
      let stepIdx = this.stepIdx;
      if (this.isSubmitReport == 0 || this.isSubmitReport == 2) {
        this.saveReportNew();
      }
      stepIdx++;
      if (this.experimentStepList[stepIdx].userReportStep) {
        this.stepIdx = stepIdx;
        this.descVal =
          this.experimentStepList[
            stepIdx
          ].userReportStep.experimentalDescription;
        this.tableVal =
          this.experimentStepList[stepIdx].userReportStep.experimentTable;
        this.searchReportInfo();
      } else {
        this.stepIdx = stepIdx;
        this.descVal = "";
        this.tableVal = "";
        this.searchReportInfo();
      }

      /**
       else {
                console.log("此步没有用户提交的实验报告");
                this.userReportState = 0;
                this.disableSaveReportBtn = false;

            }
       */
    },
    //上一步
    upStep: function () {
      let stepIdx = this.stepIdx;
      stepIdx--;
      this.stepIdx = stepIdx;
      this.searchReportInfo();
    },
    //保存报告
    saveReportGoFun: function () {
      if (this.disableSaveReportBtn || this.isSubmitReport == 1) {
        return;
      } else {
        this.userReportState = 0;
        this.saveReportNew();
      }
    },
    //保存并提交
    saveReportSubmit: function () {
      if (this.disableSaveReportBtn || this.isSubmitReport == 1) {
        return;
      } else {
        this.userReportState = 1;
        this.saveReport();
      }
    },
    //保存报告
    saveReportNew: function () {
      let stepIdx = this.stepIdx;
      let that = this;
      if (that.experimentStepList && that.experimentStepList.length > 0) {
        console.log("========saveReportNew=========", {
          userReportStepId:
            that.experimentStepList[stepIdx].userReportStep == null
              ? ""
              : that.experimentStepList[stepIdx].userReportStep
                  .userReportStepId,
          reportId: that.experimentStepList[stepIdx].reportId, //报告id
          reportStepId: that.experimentStepList[stepIdx].reportStepId, //报告步骤id
          sort: that.experimentStepList[stepIdx].eSort, //报告步骤排序
          experimentId: that.experimentId, //实验id
          experimentalDescription: that.descVal, //实验描述（思考题也是这个字段）
          experimentTable: that.tableVal ? JSON.stringify(that.tableVal) : "", //  实验表格
          photo: that.photo, //图片
          nodeId: that.nodeIdArr.join(","),
          fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
          token: that.token,
          //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
          userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
        });

        this.$api
          .updateUserReportStep({
            userReportStepId:
              that.experimentStepList[stepIdx].userReportStep == null
                ? ""
                : that.experimentStepList[stepIdx].userReportStep
                    .userReportStepId,
            reportId: that.experimentStepList[stepIdx].reportId, //报告id
            reportStepId: that.experimentStepList[stepIdx].reportStepId, //报告步骤id
            sort: that.experimentStepList[stepIdx].eSort, //报告步骤排序
            experimentId: that.experimentId, //实验id
            experimentalDescription: that.descVal, //实验描述（思考题也是这个字段）
            experimentTable: that.tableVal ? JSON.stringify(that.tableVal) : "", //  实验表格
            photo: that.photo, //图片
            nodeId: that.nodeIdArr.join(","),
            fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
            token: that.token,
            //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
            userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
          })
          .then((res) => {
            that.$message({
              message: "保存成功",
              type: "warning",
            });
          });
      } else {
        console.log("========saveReportNew1111=========", {
          reportId: that.reportInfo.reportId || "", //报告id
          experimentId: that.experimentId, //实验id
          photo: that.photo, //图片
          nodeId: that.nodeIdArr.join(","),
          fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
          token: that.token,
          //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
          userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
        });
        this.$api
          .updateUserReportStep({
            reportId: that.reportInfo.reportId || "", //报告id
            experimentId: that.experimentId, //实验id
            photo: that.photo, //图片
            nodeId: that.nodeIdArr.join(","),
            fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
            token: that.token,
            //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
            userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
          })
          .then((res) => {
            that.$message({
              message: "保存成功",
              type: "warning",
            });
          });
      }
    },

    //保存报告
    saveReport: function () {
      let stepIdx = this.stepIdx;
      let that = this;
      if (that.experimentStepList && that.experimentStepList.length > 0) {
        console.log("========saveReport=========", {
          userReportStepId:
            that.experimentStepList[stepIdx].userReportStep == null
              ? ""
              : that.experimentStepList[stepIdx].userReportStep
                  .userReportStepId,
          reportId: that.experimentStepList[stepIdx].reportId, //报告id
          reportStepId: that.experimentStepList[stepIdx].reportStepId,
          sort: that.experimentStepList[stepIdx].eSort,
          experimentId: that.experimentId, //实验id
          experimentalDescription: that.descVal, //实验描述（思考题也是这个字段）
          experimentTable: that.tableVal, //  实验表格
          photo: that.photo, //图片
          nodeId: that.nodeIdArr.join(","),
          fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
          token: that.token,
          //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
          userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
        });
        this.$api
          .updateUserReportStep({
            userReportStepId:
              that.experimentStepList[stepIdx].userReportStep == null
                ? ""
                : that.experimentStepList[stepIdx].userReportStep
                    .userReportStepId,
            reportId: that.experimentStepList[stepIdx].reportId, //报告id
            reportStepId: that.experimentStepList[stepIdx].reportStepId,
            sort: that.experimentStepList[stepIdx].eSort,
            experimentId: that.experimentId, //实验id
            experimentalDescription: that.descVal, //实验描述（思考题也是这个字段）
            experimentTable: that.tableVal, //  实验表格
            photo: that.photo, //图片
            nodeId: that.nodeIdArr.join(","),
            fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
            token: that.token,
            //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
            userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
          })
          .then((res) => {
            stepIdx++;
            that.photo = "";
            that.descVal = "";
            that.tableVal = "";
            that.stepIdx = stepIdx;
            that.fileSavedNameArr = [];
            that.nodeIdArr = [];
            that.showSubmitReport = false;
            that.$message({
              message: "上传成功",
              type: "warning",
            });
            if (that.userReportState == 1) {
              that.stepIdx = 0;
              that.searchReportInfo();
              that.showSubmitReport = false;
            }
          });
      } else {
        console.log("========saveReport1111=========", {
          reportId: that.reportInfo.reportId || "", //报告id
          experimentId: that.experimentId, //实验id
          photo: that.photo, //图片
          nodeId: that.nodeIdArr.join(","),
          fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
          token: that.token,
          //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
          userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
        });
        this.$api
          .updateUserReportStep({
            reportId: that.reportInfo.reportId || "", //报告id
            experimentId: that.experimentId, //实验id
            photo: that.photo, //图片
            nodeId: that.nodeIdArr.join(","),
            fileSavedName: that.fileSavedNameFiles.join(","), //附件名称，即上传接口返回的
            token: that.token,
            //nodeId: that.experimentStepList[stepIdx].userReport.nodeId,//附件id，即上传接口返回的 nodeId，多个的话用豆号分隔
            userReportState: that.userReportState, // 0不提交-下一步保存，1提交-保存并提交；
          })
          .then((res) => {
            that.photo = "";
            that.descVal = "";
            that.tableVal = "";
            that.fileSavedNameArr = [];
            that.nodeIdArr = [];
            that.showSubmitReport = false;
            that.$message({
              message: "上传成功",
              type: "warning",
            });
            if (that.userReportState == 1) {
              that.stepIdx = 0;
              that.searchReportInfo();
              that.showSubmitReport = false;
            }
          });
      }
    },

    //上传图片删除
    handleRemove(file, fileList) {
      for (let i = 0; i < this.fileSavedNameArr.length; i++) {
        if (this.fileSavedNameArr[i] == file.name) {
          this.fileSavedNameArr.splice(i, 1);
          this.nodeIdImageArr.splice(i, 1);
          break;
        }
      }
    },
    // 上传文件删除
    handleRemoveFile(file, fileList) {
      console.log(file);
      for (let i = 0; i < this.fileSavedNameFiles.length; i++) {
        if (this.fileSavedNameFiles[i] == file.name) {
          this.fileSavedNameFiles.splice(i, 1);
          this.filePaths.splice(i, 1);
          this.nodeIdArr.splice(i, 1);
          // this.nodeIdFileArr.splice(i,1)
          break;
        }
      }
    },
    //上传作业图片删除
    handleRemoveHomework(file, fileList) {
      let index = this.nodeIdImgs.findIndex((item) => item.name == file.name);
      this.nodeIdImgs.splice(index, 1);
    },
    // 上传作业文件删除
    handleRemoveHomeworkFile(file, fileList) {
      let index = this.nodeIdFiles.findIndex((item) => item.name == file.name);
      this.nodeIdFiles.splice(index, 1);
      this.hwFilePaths.splice(index, 1);
    },
    //上传图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传文件预览
    handleFilePreview(file) {
      let pathObj = this.filePaths.filter((item) => item.name == file.name);
      // const link = document.createElement('a'); // 生成一个a标签。
      // link.href = window.URL.createObjectURL(file.url); // href属性指定下载链接
      // link.download = file.name; // dowload属性指定文件名
      // link.click();
      let url = file.url ? file.url : pathObj[0].path;
      window.open(url);
    },
    //上传作业文件预览
    handleHomeworkFilePreview(file) {
      let pathObj = this.hwFilePaths.filter((item) => item.name == file.name);
      // const link = document.createElement('a'); // 生成一个a标签。
      // link.href = window.URL.createObjectURL(file.url); // href属性指定下载链接
      // link.download = file.name; // dowload属性指定文件名
      // link.click();
      // let url = file.url ? file.url : pathObj[0].path;
      // window.open(url);
      let url = file.url ? file.url : pathObj[0].path;
      AsingleFile(url);
    },
      //上传作业文件预览
    handleHomeworkFilePreviewNew(url) {
      AsingleFile(url);
    },
    // 上传作业文件
    beforeUploadHomeworkFile(param) {
      let that = this;
      this.$api
        .getStsToken({
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            let isOss = res.data.isOss;
            let reData = res.data;
            //本地上传
            if ("0" == isOss) {
              let fd = new FormData();
              fd.append("file", param.file);
              fd.append("sourceName", param.file.name);
              fd.append("relative", "homeworkFile");
              fd.append("appCode", "homeworkFile");
              const axios = require("axios");
              axios
                .post(this.$http.httpConfig + this.$http.uploadImgNodeId, fd)
                .then(function (re) {
                  if (re.data.code == "10000") {
                    let resData = re.data;
                    let obj = {
                      nodeId: resData.nodeId,
                      name: resData.originalFileName,
                    };
                    that.nodeIdFiles.push(obj);
                    that.file = resData.filePreviewPathFull;
                    that.hwFilePaths.push({
                      name: resData.originalFileName,
                      path: resData.filePreviewPathFull,
                    });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // always executed
                });
            } else {
              const OSS = require("ali-oss");
              let folder = "homeworkFile";
              const client = new OSS({
                accessKeyId: reData.accessKeyId,
                accessKeySecret: reData.accessKeySecret,
                stsToken: reData.securityToken,
                region: reData.regionId,
                bucket: reData.bucket,
              });
              let file = param.file;
              let d = new Date();
              let year = d.getFullYear();
              let month = d.getMonth() + 1;
              let day = d.getDate();
              let savePath = folder + "/" + year + "/" + month + day + "/";
              let size = file.size;
              let fileName = file.name;
              let index1 = fileName.lastIndexOf(".");
              let fileExt = fileName.substring(index1);
              //不带点
              let fileExt2 = fileName.substring(index1 + 1);
              let code = "";
              for (let i = 0; i < 6; i++) {
                let radom = Math.floor(Math.random() * 10);
                code += radom;
              }
              let saveName = d.getTime() + code + fileExt;
              let storeAs = savePath + saveName;
              const data = file;

              async function putObject() {
                try {
                  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                  // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
                  // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
                  const result = await client.put(storeAs, data);
                  if ("200" == result.res.statusCode) {
                    let imgUrl = result.res.requestUrls[0];
                    let filePreviewPathFull = imgUrl.split("?")[0];
                    //附件上传
                    let resData = {
                      appCode: folder,
                      fileExtName: fileExt2,
                      fileName: fileName,
                      fileSavedName: saveName,
                      fileSize: size,
                      path: filePreviewPathFull,
                      objectName: "/" + storeAs,
                    };
                    that.$api.addNodeInfo(resData).then((re) => {
                      let rd = re.data;
                      let obj = {
                        nodeId: rd,
                        name: fileName,
                      };
                      that.nodeIdFiles.push(obj);
                      that.file = filePreviewPathFull;
                      that.hwFilePaths.push({
                        name: fileName,
                        path: filePreviewPathFull,
                      });
                    });
                  } else {
                    that.$message("上传失败");
                  }
                } catch (e) {
                  that.$message("上传失败");
                  console.log(e);
                }
              }

              putObject();
            }
          }
        });
    },
    // 上传文件
    beforeUploadFile(param) {
      let fd = new FormData();
      fd.append("file", param.file);
      fd.append("sourceName", param.file.name);
      fd.append("relative", "homeworkFile");
      fd.append("appCode", "homeworkFile");
      let that = this;
      var config = {
        onUploadProgress: function (e) {
          console.log(e);
        },
      };
      const axios = require("axios");
      axios
        .post(this.$http.httpConfig + this.$http.uploadImgNodeId, fd)
        .then(function (re) {
          console.log(re);
          if (re.data.code == "10000") {
            let resData = re.data;
            that.nodeIdArr.push(resData.nodeId);
            // that.nodeIdFileArr.push(resData.nodeId);
            that.fileSavedNameFiles.push(resData.originalFileName);
            that.file = resData.filePreviewPathFull;
            that.filePaths.push({
              name: resData.originalFileName,
              path: resData.filePreviewPathFull,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    exportReport() {
      let that = this;
      this.$api
        .getIsHasExperimentReportApi({
          token: that.token,
          experimentId: that.experimentId,
          memberUserId: that.userInfo.userId,
        })
        .then((res) => {
          let resData = res.data || "0";
          if (0 == resData) {
            that.$message("未上传实验报告，无实验报告可导出！");
          } else {
            window.open(
              that.$http.httpConfig +
                that.$http.exportBatchReport +
                "&token=" +
                that.token +
                "&experimentId=" +
                that.experimentId +
                "&classId=" +
                that.radio.classId+
                "&memberUserId=" +
                that.userInfo.userId
            );
          }
        });
    },
    getExperimentPractice() {
      let that = this;
      //获取实验练习
      this.$api
        .getFrontCurriculumPracticeListApi({
          token: this.token,
          experimentId: that.experimentId,
          curriculumResourceId: that.curriculumResourceId,
        })
        .then((res) => {
          if (res.code == 10000) {
            console.log(res);
            let resData = res.data ?? [];
            if (resData && resData.length > 0) {
              that.practiceList = resData;

              let isSubmitPaperN = resData[0].isSubmitPaper ?? 0;
              if (0 == isSubmitPaperN) {
                that.isSubmitPaper = false;
              } else {
                that.isSubmitPaper = true;
              }
            }
            that.isShowPracticeParams = false;
            that.isShowPracticeList = true;
            that.isShowPracticeContent = false;
          }
        });
    },
    // 开始、继续练习按钮
    startPractice(id) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let that = this;
      this.$router.push({
        path: "/Theonlinetest/PracticeTests/viewItem",
        query: {
          papersId: id,
          isStart: 1,
          isSubmitExam: true,
          curriculumResourceId: that.curriculumResourceId,
          experimentId: that.experimentId,
        },
      });
    },
    // 查看练习
    getUserPracticeList(id, isConducts, papersId) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let that = this;
      if (isConducts) {
        this.$router.push({
          path: "/Theonlinetest/PracticeTests/viewItem",
          query: {
            userPapersId: id,
            papersId: papersId,
            isPreview: true,
            curriculumResourceId: that.curriculumResourceId,
            experimentId: that.experimentId,
          },
        });
      } else {
        this.$router.push({
          path: "/Theonlinetest/PracticeTests/viewItem",
          query: {
            userPapersId: id,
            papersId: papersId,
            isView: true,
            curriculumResourceId: that.curriculumResourceId,
            experimentId: that.experimentId,
          },
        });
      }
    },
    goPracticeBack() {
      //返回
      this.enableEdit();
      //获取实验练习信息
      this.getExperimentPractice();
    },
    getExperimentExam() {
      let that = this;
      //获取实验考试
      this.$api
        .getFrontCurriculumExaminationListApi({
          token: this.token,
          experimentId: that.experimentId,
          curriculumResourceId: that.curriculumResourceId,
          channelId: this.$http.channelId,
        })
        .then((res) => {
          if (res.code == 10000) {
            console.log(res);
            let resData = res.data ?? [];
            if (resData && resData.length > 0) {
              that.examList = resData;

              let isSubmitPaperN = resData[0].isSubmitPaper ?? 0;
              if (0 == isSubmitPaperN) {
                that.isSubmitExam = false;
              } else {
                that.isSubmitExam = true;
              }
            }
            that.isShowExamParams = false;
            that.isShowExamList = true;
            that.isShowExamContent = false;
          }
        });
    },
    // 开始考试，继续考试按钮
    startExam(id) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let that = this;
      this.$router.push({
        path: "/Theonlinetest/TestPaper/viewItem",
        query: {
          papersId: id,
          isStart: 1,
          isSubmitExam: true,
          curriculumResourceId: that.curriculumResourceId,
          experimentId: that.experimentId,
        },
      });
    },
    // 查看考试
    getUserExamList(id, isConducts, papersId) {
      if (this.token == "") {
        $("#loginBtn").click();
        localStorage.removeItem("pc_token");
        localStorage.removeItem("pc_data");
        return false;
      }
      let that = this;
      if (isConducts) {
        this.$router.push({
          path: "/Theonlinetest/TestPaper/viewItem",
          query: {
            userPapersId: id,
            papersId: papersId,
            isPreview: true,
            curriculumResourceId: that.curriculumResourceId,
            experimentId: that.experimentId,
          },
        });
      } else {
        this.$router.push({
          path: "/Theonlinetest/TestPaper/viewItem",
          query: {
            userPapersId: id,
            papersId: papersId,
            isView: true,
            curriculumResourceId: that.curriculumResourceId,
            experimentId: that.experimentId,
          },
        });
      }
    },

    prohibitEdit() {
      // 禁止右键菜单
      document.oncontextmenu = function () {
        return false;
      };
      // 禁止文字选择
      document.onselectstart = function () {
        return false;
      };
      // 禁止复制
      document.oncopy = function () {
        return false;
      };
      // 禁止剪切
      document.oncut = function () {
        return false;
      };
      // 禁止粘贴
      document.onpaste = function () {
        return false;
      };
    },
    enableEdit() {
      // 启用右键菜单
      document.oncontextmenu = function () {
        return true;
      };
      // 启用文字选择
      document.onselectstart = function () {
        return true;
      };
      // 启用复制
      document.oncopy = function () {
        return true;
      };
      // 启用剪切
      document.oncut = function () {
        return true;
      };
      // 启用粘贴
      document.onpaste = function () {
        return true;
      };
    },
  },

  watch: {
    quesForm: function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
    },
    practiceQuesForm: function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
    },
    examQuesForm: function (newValue, oldValue) {
      console.log(newValue);
      console.log(oldValue);
    },
    $route: {
      immediate: true,
      handler(to, from) {
        // console.log('路由变化了from==', from)
        // console.log('to==', to)
      },
    },
  },
};
</script>

<style scoped>
.experimentInfoStuContainer {
  display: block;
  /* margin: 1.2rem auto 0.5rem; */
  /* padding: 0.4rem 0.3rem; */
  padding: 0.05rem 1.1rem;
  /* width: 80%; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  /* font-size: 12px !important; */
}
.txtLeft {
  text-align: left;
}
.searchInp {
  position: relative;
  width: 1.8rem;
}
.searchInp .el-input input {
  padding-right: 0.3rem;
  border-radius: 0.3rem;
  width: 1.8rem;
  font-size: 0.12rem;
  height: 0.4rem;
}
.box {
  width: 24%;
  margin-right: 1%;
  height: 1rem;
  position: absolute;
  z-index: 2000;
}
.txtOverFlow1 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.txtOverFlow2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courseImg {
  width: 2rem;
  height: 2rem;
}

.courseName {
  border-bottom: 0.01rem solid #efefef;
  display: block;
  font-size: 0.2rem;
  padding-bottom: 0.12rem;
  margin-bottom: 0.12rem;
  line-height: 0.3rem;
  margin-top: 0.2rem;
  color: #444;
  text-align: left;
}

.experimentInfo {
  color: #666;
  font-size: 0.14rem;
  text-align: left;
  line-height: 0.25rem;
}

@media screen and (max-width: 720px) {
  .courseName {
    font-size: 0.18rem;
    margin-bottom: 0.1rem;
    line-height: 0.3rem;
    padding-bottom: 0.1rem;
  }

  .experimentInfo {
    font-size: 0.13rem;
    text-align: left;
    line-height: 0.25rem;
  }

  .courseImg {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.experimentTabBox {
  /* margin-top: 0.3rem; */
}

.el-tabs--border-card > .el-tabs__content {
  padding: 0.2rem 0.3rem;
}

.coursewareItem {
  width: 49%;
  margin-bottom: 0.08rem;
  padding: 0.1rem 0.1rem 0.1rem;
  box-sizing: border-box;
  background: #fff;
  /* border-bottom-right-radius: 18rpx; */
  position: relative;
  flex-wrap: wrap;
  font-size: 14px;
  text-align: left;
  margin-right: 2%;
}

.coursewareItem:nth-child(2n) {
  margin-right: 0;
}

.iconFile {
  width: 0.4rem;
  height: 0.5rem;
  margin-right: 0.15rem;
  object-fit: cover;
}
.iconPngFile {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.15rem;
  object-fit: cover;
}

.iconLoad {
  margin-left: 0.1rem;
  width: 0.25rem;
  height: 0.25rem;
  cursor: pointer;
}
.flex_main,
.flex_page_main {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.coursewareTime {
  color: #666;
  font-size: 0.05rem;
}

@media screen and (max-width: 400px) {
  .el-tabs--border-card > .el-tabs__content {
    padding: 0.12rem;
  }

  .courseFileItem {
    margin-left: 0;
    width: 100%;
  }

  ul {
    padding: 0;
  }
}

/deep/ .el-card__header {
  padding: 0.08rem 0.1rem !important;
}

/deep/ .el-card__body {
  padding: 0px;
  line-height: 0.34rem;
}

.nodataTxt {
  color: #666;
  font-size: 0.14rem;
  text-align: center;
  margin: 0.2rem 0;
}

.coursewareName {
  font-size: 0.14rem;
  color: #444;
  text-align: left;
  max-height: 0.46rem;
  line-height: 0.23rem;
  margin-bottom: 0.1rem;
}

.updateTime {
  font-size: 0.12rem;
  color: #666;
  text-align: left;
}

.leftBtn {
  border-right: 0.01rem solid #efefef;
}

.fileBtn {
  float: right;
  padding: 0.03rem 0;
  text-align: center;
  width: 50%;
  height: 100%;
}

/*在线答题*/
.courseQuestionsBox {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.onlineContainer {
  width: 98%;
  /*display: block;
        margin: 0 auto;*/
  text-align: left;
}

.onlineTit {
  color: #444;
  font-size: 0.16rem;
  line-height: 0.3rem;
}

.quesType {
  color: #999;
  font-size: 0.14rem;
  margin: 0.1rem 0;
}

/deep/ .el-radio {
  display: block;
  padding: 0.05rem;
}

/deep/ .el-textarea__inner {
  min-height: 1rem !important;
  overflow: hidden;
}

/deep/ .el-checkbox__label {
  line-height: 0.3rem !important;
}

li {
  list-style: none;
}

/*在线问答*/
.courseQuestionsBox_item {
  height: 100%;
  padding: 0.1rem;
  box-sizing: border-box;
}

.courseQuestionInps {
  margin-bottom: 0.09rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/deep/ .courseQuestionsBox_item .el-card__body {
  flex-direction: column;
}

@media screen and (max-width: 720px) {
  .courseQuestionContainer {
    width: 100%;
  }

  .courseQuestionsBox_item {
    padding: 0.15rem;
  }

  .courseQuestionInps {
    /* justify-content: flex-end; */
  }

  .courseQuestionInps > .flex {
    width: 100%;
    margin-bottom: 0.1rem;
  }
}

.courseQuestionsItem {
  border-bottom: 0.01rem solid #eeeeee;
  padding-bottom: 0.1rem;
  padding-top: 0.1rem;
}

.mineQuesBox {
  width: 100%;
  margin-bottom: 0.3rem;
}

.mineIcon {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.mineInput {
  width: 80%;
  border-radius: 0.18rem;
  background: #f7f7f7;
  color: #969696;
  font-size: 0.14rem;
  line-height: 0.4rem;
  padding-left: 0.1rem;
  text-align: left;
}

.userInfoBox {
  margin-bottom: 0.15rem;
}

.userName {
  color: #666;
  font-size: 0.08rem;
}

.userInfoTxt {
  color: #999;
  font-size: 0.05rem;
  line-height: 0.2rem;
  text-align: left;
}

@media screen and (max-width: 720px) {
  .userInfoTxt {
    font-size: 0.05rem;
  }
}

.zanIcon {
  width: 0.13rem;
  height: 0.15rem;
  margin-top: 0.03rem;
  margin-right: 0.04rem;
}

.zanNum {
  color: #999999;
  font-size: 0.12rem;
  width: 0.4rem;
}

.moreIcon {
  width: 0.12rem;
  height: 0.1rem;
  margin-top: 0.05rem;
}

.txtContent {
  color: #444444;
  font-size: 0.06rem;
  line-height: 0.25rem;
}

.replayBox {
  margin-left: 0.55rem;
  margin-top: 0.12rem;
}

.timeTxt {
  color: #999999;
  font-size: 0.12rem;
  line-height: 0.2rem;
}

.moreReplyBox {
  padding: 0.1rem 0.15rem;
}

.moreReplyCont {
  line-height: 0.2rem;
  margin-bottom: 0.1rem;
}

.ml24 {
  margin-left: 0.12rem;
}

.mb10 {
  margin-bottom: 0.05rem;
}

.mb30 {
  margin-bottom: 0.15rem;
}

.colorBlue {
  color: #4f7daf;
}

.ft15 {
  font-size: 0.15rem;
}

.ft12 {
  font-size: 0.09rem;
}

.color4 {
  color: #444444;
}

.imageList {
  height: 0.86rem;
  width: 100%;
  overflow-x: scroll;
}

.commentImg {
  width: 0.79rem;
  height: 0.79rem;
  border-radius: 0.03rem;
  margin-right: 0.1rem;
}

.onImg {
  margin-right: 0px;
  border: 0.01rem solid #28b28b;
}

.closeImg {
  width: 0.14rem;
  height: 0.14rem;
  position: relative;
  left: -15%;
  top: -78%;
}

.moreCloseImg {
  width: 0.14rem;
  height: 0.14rem;
  position: relative;
  left: 81%;
  top: -112%;
}

.commentImgItem {
  width: 0.5rem;
  height: 0.75rem;
}

.commentImgList {
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 0.1rem;
}

.picsItemBox {
  margin: 0.1rem 0;
  text-align: left;
}
.picsItemBox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .picsItem {
      width: 1rem;
      height: 1rem;
      margin-right: 0.1rem;
      margin-bottom: 0.1rem;
    } */
.optionsItem {
  width: 0.4rem;
  height: 0.4rem;
  background: #f6f7f9;
  border-radius: 0.07rem;
}

/*实验报告*/
.experimentItemContainer {
  width: 100%;
  color: #444;
  font-size: 0.08rem;
  text-align: center;
}

.experiContent {
  color: #444444;
  font-size: 0.1rem;
  line-height: 0.3rem;
}
.mr20 {
  margin-right: 0.2rem;
}
.submitBtn {
  display: block;
  margin: 0 auto;
  margin-top: 0.1rem;
}

.experimentReportTitle {
  font-size: 0.1rem;
  line-height: 0.2rem;
  text-align: left;
  font-weight: bold;
}

.title {
  font-size: 0.09rem;
  line-height: 0.3rem;
  text-align: left;
  font-weight: bold;
}

.experimentName {
  font-size: 0.11rem;
  color: #444;
  font-weight: bold;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.experimentImgList,
.homeworkImgList {
  text-align: left;
  /* margin: 0.2rem 0; */
  font-size: 0.1rem;
}
.experimentImgList {
  text-align: left;
  margin: 0.2rem 0;
  font-size: 0.1rem;
}

.fixedBttom {
  width: 100%;
  display: block;
  margin: 0 auto;
}

#courseQuestionsBox2 /deep/ .el-row {
  margin: 0 0 0.2rem 0 !important;
  height: 1.2rem;
}

/deep/ .el-badge {
  height: 0.35rem;
}

.bottomCommentBox {
  border-top: 0.01rem solid #efefef;
  width: 100%;
  padding: 0.15rem 0;
  margin-top: 0.1rem;
  height: 0.35rem;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}
.replyBtn {
  height: 0.2rem;
  font-size: 0.08rem;
}

.commentBtnBox {
  position: relative;
  min-width: 80px;
}

.commentBtn {
  position: absolute;
  top: 0px;
  right: 0px;
}

/deep/ .el-form-item__content {
  margin-left: 0 !important;
}

/deep/ .el-form-item {
  margin-bottom: 0 !important;
}

/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: green;
  background: green;
}

/deep/ .el-radio__input.is-checked + .el-radio__label {
  color: green;
}

.redRadioBox /deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: red;
  background: red;
}

.redRadioBox /deep/ .el-radio__input.is-checked + .el-radio__label {
  color: red;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: green;
  background-color: green;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: green;
}

.redCheckBox /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
  border-color: red !important;
  background-color: red !important;
}

.redCheckBox /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: red !important;
}

/deep/ .el-checkbox {
  display: block;
}

.submitQuesBtn {
  display: block;
  margin: 0 auto;
  margin-top: 0.2rem;
}

.trueAnswerTxt {
  margin: 0.2rem 0;
  color: #444;
  font-size: 0.14rem;
  line-height: 0.25rem;
}

.answerVal {
  text-indent: 0.56rem;
}

.experDesc {
  margin: 0.1rem 0;
  font-size: 0.08rem;
  color: #444;
  text-align: left;
}

.fileArrTitle {
  margin-left: 16%;
}

.fileArrBox {
  width: 50%;
  display: block;
  margin: 0.1rem auto;
  padding: 0;
}

@media screen and (max-width: 720px) {
  .fileArrTitle {
    margin-left: 0%;
  }

  .fileArrBox {
    width: 100%;
  }
}

.fileArrItem {
  height: 0.3rem;
  line-height: 0.3rem;
  border-bottom: 0.01rem solid #efefef;
  font-size: 0.14rem;
  color: #444;
}

.leftFileItem {
  flex: 1;
  text-align: left;
}

.rightFileItem {
  text-align: right;
  /* color: #ffc911; */
  color: #18cca7;
}

.rightFileItem a {
  text-decoration: none;
}
.flex_wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.colorY {
  /* color: #ffc911; */
  color: #18cca7;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item-status-label {
  position: absolute;
  right: -0.15rem;
  top: -0.06rem;
  width: 0.4rem;
  height: 0.24rem;
}

/deep/ .v-modal {
  z-index: 1 !important;
  opacity: 0 !important;
  background: #fff !important;
}

.experimentInfoContent {
  font-size: 0.05rem;
  margin-bottom: 0.1rem;
}
.flex_page {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.borderBox {
  border: 1px solid #ededed;
  box-shadow: 0 1px 3px rgba(18, 18, 18, 0.1);
}
.flex_just_between,
.flex_page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.flex_align_center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.experimentInfoTabs {
  padding: 0.1rem 0.1rem;
  font-size: 0.07rem;
  color: #444;
  border-bottom: 1px solid #f0f2f7;
}

.experimentInfoTabs > div {
  margin-right: 0.4rem;
  cursor: pointer;
}

.experimentInfoTabs > div:hover {
  color: #4d3d0b;
}

.experimentInfoBox {
  padding: 0.08rem 0.2rem 0.4rem;
}

.experimentModel {
  font-size: 0.15rem;
}

.modelBox {
  padding: 0.1rem 0.1rem;
  /* text-align: left; */
  margin-bottom: 0.1rem;
  text-align: left;
  margin-top: 0.1rem;
}

.modeTitle {
  font-size: 0.16rem;
  line-height: 1.4;
  color: #444;
  margin-bottom: 0.1rem;
  border-bottom: 2px solid #f0f2f7;
  padding-bottom: 0.1rem;
  font-weight: 600;
}

.modeTitle .more {
  font-weight: normal;
  font-size: 0.13rem;
  cursor: pointer;
}

.courseQuestionsMode > div.mode {
  padding: 0.2rem 0;
  border-bottom: 1px dashed #f0f2f7;
}

.courseQuestionsMode .descp {
  color: #868686;
  margin-top: 0.05rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.courseQuesPage {
  justify-content: flex-end;
  padding-top: 0.15rem;
}

.courseQuesPage > div {
  color: #fff;
  background: #ffc911;
  cursor: pointer;
  padding: 0 0.12rem;
  height: 0.22rem;
  line-height: 0.23rem;
  border-radius: 5px;
  margin-left: 0.1rem;
}

.courseQuesPage > div.disable {
  background: #e3e3e3;
}

.returnIcon {
  cursor: pointer;
  color: #666;
  width: 0.18rem;
}

.tableDiv {
  width: 100%;
  overflow-x: auto;
}

.tableBlock {
  width: 100%;
  border-left: 1px solid #dcdfe6;
}

.trBlock {
  display: flex;
}

.trBlock:last-of-type .tdBlock {
  border-bottom: 1px solid #dcdfe6;
}

.tdBlock {
  width: 100px;
  height: 40px;
  display: flex;
  font-size: 0.09rem;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  padding: 0px 15px;
}

.tdBlock input {
  width: 100%;
  height: 20px;
  border: 1px solid #fff;
  outline: none;
}

@media screen and (min-width: 720px) and (max-width: 1280px) {
  .textRight {
    text-align: right;
  }
}

@media screen and (max-width: 720px) {
  .textRight {
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .textRight {
    text-align: right;
  }
}

.bg_gary {
  padding: 0.09rem 0.15rem;
  background: #f3f3f3;
  /* margin: 0.2rem 0; */
  box-sizing: border-box;
}

.left {
  height: 50px;
  background-color: #f3f3f3;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  margin: 0.1rem 0;
  padding: 0rem 0.15rem;
}

.right {
  flex: 1;
  background-color: #f3f3f3;
  height: 50px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: 0.1rem 0;
  padding: 0rem 0.15rem;
}

.spanRight {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  margin: 0.1rem 0;
  padding: 0rem 0.15rem;
}

.div_box_center {
  justify-content: center;
  padding: 0.15rem;
}
.TimeTexrt {
  display: inline-block;
  margin: 5px;
  color: #999;
}
.TimeTexrts {
  display: inline-block;
  margin: 15px;
  color: #999;
  font-size: 12px;
}
/* 课前预习 */
.collapse::v-deep .el-collapse {
  border-top: 0px solid #ebeef5;
  border-bottom: 0px solid #ebeef5;
}
.collapse::v-deep .el-collapse-item__header {
  font-size: 14px;
  font-weight: 700;
}
.bgf4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  background: #f4f3f3;
}
</style>
