<!--课程提问弹框-->
<template>
    <div class="quesPopContainer">
        <el-form ref="form" label-width="0rem">
            <el-form-item class="login_item">
                <el-input type="textarea" v-model="commentVal" placeholder="我要说几句" style="min-height:1rem"></el-input>
            </el-form-item>
            <div>
                <el-upload
                    action=""
                    list-type="picture-card"
                    name="uploadImg"
                    :limit="6"
                    :auto-upload="true"
                    :on-preview="handlePictureCardPreview"
                    :http-request="beforeUploadImg"
                    multiple
                    ref="uploadPop"
                    :with-credentials="true"
                    :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </div>
            <el-button v-if="copyQuesAnswerId!=''" class="submitBtn" type="primary" @click="submitContent">回复</el-button>
            <el-button v-else class="submitBtn" type="primary" @click="submitContent">发表内容</el-button>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "quesPop",
        data() {
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                commentImagesArr: [],
                commentVal:"",
                token: localStorage.getItem("pc_token"),
                copyCurResId:this.curriculumResourceId,
                copyQuesAnswerId:this.questionAnswerId
            }
        },
        props:{
            curriculumResourceId:String,
            questionAnswerId:String
        },
        methods:{
            //上传图片预览
            handleRemove(file, fileList) {
                console.log(file, fileList);
                console.log(this.commentImagesArr);
                for(let i = 0;i<this.commentImagesArr.length;i++) {
                    if(this.commentImagesArr[i] == file.name) {
                        this.commentImagesArr.splice(i,1);
                        break;
                    }
                }
                console.log(this.commentImagesArr);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            beforeUploadImg(param) {
              let that = this;
              that.$api.getStsToken({
                channelId: that.$http.channelId,
              }).then(res => {
                if(res.code == 10000){
                   let isOss = res.data.isOss;
                  let reData = res.data;
                  //本地上传
                  if ("0" == isOss) {
                    let fd = new FormData();
                    fd.append('file', param.file)
                    fd.append('sourceName', param.file.name)
                    fd.append('relative', "userReportFile")
                    fd.append('appCode', "userReportFile");
                    const axios = require('axios')
                    axios.post(this.$http.httpConfig+this.$http.uploadImgNodeId, fd)
                    .then(function (re) {
                        if (re.data.code == '10000') {
                            let resData = re.data;
                            let obj = {
                                questionPic: resData.filePreviewPathFull
                            }
                            that.commentImagesArr.push(obj);
                        }
                    }).catch(function (error) {
                        console.log(error)
                    })
                    .then(function () {
                        // always executed
                    })
                      
                  } else {
                    const OSS = require('ali-oss')
                    let folder = 'userReportFile';
                    const client = new OSS({
                      accessKeyId: reData.accessKeyId,
                      accessKeySecret: reData.accessKeySecret,
                      stsToken: reData.securityToken,
                      region: reData.regionId,
                      bucket: reData.bucket
                    });
                    let file = param.file;
                    let d = new Date();
                    let year = d.getFullYear();
                    let month = d.getMonth() + 1;
                    let day = d.getDate();
                    let savePath = folder + '/' + year + '/' + month + day + '/';
                    let size = file.size;
                    let fileName = file.name;
                    let index1 = fileName.lastIndexOf('.');
                    let fileExt = fileName.substring(index1);
                    //不带点
                    let fileExt2 = fileName.substring(index1 + 1);
                    let code = "";
                    for (let i = 0; i < 6; i++) {
                      let radom = Math.floor(Math.random() * 10);
                      code += radom;
                    }
                    let saveName = d.getTime() + code + fileExt;
                    let storeAs = savePath + saveName;
                    const data = file;

                    async function putObject() {
                      try {
                        // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
                        // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
                        // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
                        const result = await client.put(storeAs, data);
                        if ('200' == result.res.statusCode) {
                          let imgUrl = result.res.requestUrls[0];
                          let filePreviewPathFull = imgUrl.split("?")[0];
                          //附件上传
                          let resData = {
                            appCode: folder,
                            fileExtName: fileExt2,
                            fileName: fileName,
                            fileSavedName: saveName,
                            fileSize: size,
                            path: filePreviewPathFull,
                            objectName: "/" + storeAs
                          };
                            that.$api.addNodeInfo(resData)
                            .then((res) => {
                                let obj = {
                                questionPic: filePreviewPathFull
                                }
                                that.commentImagesArr.push(obj);
                            });

                        } else {
                                that.$message('上传失败');
                        }

                      } catch (e) {
                        that.$message('上传失败');
                        console.log(e);
                      }
                    }

                    putObject();
                  }
                    
                }
              })
              
            },
            submitContent() {
                if (this.commentVal == "") {
                    this.$message('请输入发表内容');
                    return;
                }
                let that = this;
                if(that.copyQuesAnswerId != "") {
                    this.$api.updateCurriculumQuestionReply({
                        token: this.token,
                        replyDesc: that.commentVal,//问题内容
                        questionAnswerId: that.copyQuesAnswerId,//问题id
                        questionPicStr: JSON.stringify(that.commentImagesArr)
                    })
                    .then((res) => {
                        if (res.code == 10000) {
                            that.$layer.closeAll();
                            that.$layer.msg('回复成功',{
                                shade:false
                            });
                            that.$router.go(0)
                        }
                    });
                }else {
                    this.$api.updateCurriculumQuestionAnswer({
                        token: this.token,
                        curriculumResourceId: that.copyCurResId,//课程id
                        questionsDes: that.commentVal,//问题内容
                        questionAnswerId: that.copyQuesAnswerId,//问题id
                        questionPicStr: JSON.stringify(that.commentImagesArr)
                    })
                    .then((res) => {
                        if (res.code == 10000) {
                            console.log(res);
                            that.$layer.closeAll();
                            that.$layer.msg('发表成功',{
                                shade:false
                            });
                            that.$router.go(0)
                        }
                    });
                }

            },
        },
        watch:{
            curriculumResourceId:function(val){
                this.copyCurResId = val;
                this.$emit('copyCurResId', val)
            },
            questionAnswerId:function(val){
                this.copyQuesAnswerId = val;
                this.$emit('copyQuesAnswerId', val)
            }
        }
    }
</script>

<style scoped>
    .quesPopContainer {
        width: 100%;
        padding-top: 0.1rem;
    }
    .el-form {
        padding: 0px 0.1rem 0.1rem;
    }
    .el-form-item__content {
        margin-left: 0px !important;
        margin-top: 0.2rem;
        height: 1.5rem;
    }
    .el-textarea__inner {
        height: 1.5rem;
        overflow: hidden;
    }
    /deep/ .el-upload--picture-card {
        width: 1rem;
        height: 1rem;
        line-height: 1;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 1rem;
        height: 1rem;
        line-height: 1;
    }
    .submitBtn {
        display: block;
        margin: 0 auto;
        margin-top: 0.1rem;
    }
    /deep/ .el-upload--picture-card{
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
    }
    /deep/ .el-textarea__inner{
        min-height: 1rem !important;
    }
    /deep/ .v-modal{
        z-index: 1!important;
        opacity: 0!important;
        background: #fff!important;
    }
</style>
