/*
 * @Description: 查看文件 
 * @Author: liqin
 * @Date: 2021-12-03 16:45:02
 * @fileName: 文件路径  /  https://t.acoer.cn/uploadfile/competition20211203/dbfb97ed-9176-4e85-bb4e-35658bb088d9.png
 */
import getFileType from './getFileType'
export default function AsingleFile(fileName) {
  if (getFileType(fileName) == 'excel' || getFileType(fileName) == 'ppt' || getFileType(fileName) == 'word') {
    window.open('https://view.officeapps.live.com/op/view.aspx?src=' + fileName)
  } else {
    window.open(fileName)
  }
}
